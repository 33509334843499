import * as React from 'react';


export default function BytesDisplay({bytes}) {
    if(isNaN(bytes)) {
        return <React.Fragment> - </React.Fragment>
    }

    if (bytes < 1000000) {
        return <React.Fragment>{Math.round((bytes * 0.001 + Number.EPSILON) * 100) / 100} KB</React.Fragment>;
    }
    else if(bytes < 1000000000)  {
        return <React.Fragment>{Math.round((bytes * 0.000001 + Number.EPSILON) * 100) / 100} MB</React.Fragment>;
    }
    else {
        return <React.Fragment>{Math.round((bytes * 0.000000001 + Number.EPSILON) * 100) / 100} GB</React.Fragment>;
    }
}