import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import RINEXServiceApi from './service';
import RINEXServiceStatusWidget from './statusWidget';
import Button from '@mui/material/Button';
const rinexservice = new RINEXServiceApi();

export default function RINEXServiceList() {
  let navigate = useNavigate();
  
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState([]);


  useEffect(() => {
    setLoading(true);
    rinexservice.list().then((response) => {
      setServices(response);
      setLoading(false);
    });
  }, []);

    return (
      <React.Fragment>
        <h1 className="page-title"><span className="fw-semi-bold">RINEX Logging</span></h1>
        <Stack direction="row">
                {services.map((service) => {
                    return (
                          <RINEXServiceStatusWidget id={service.id} />
                          );
                })}
        </Stack>
        <Button variant="contained" onClick={()=>navigate("/gnss/rinex/new")}>+ New RINEX Logger</ Button>
      </React.Fragment>
    );
}
