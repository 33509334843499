import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PeopleIcon from '@mui/icons-material/People';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import SourceService from './sourceService';
import Switch from '@mui/material/Switch';
import SourceCreate from './sourceCreate';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ButtonGroup from '@mui/material/ButtonGroup';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';

const sourceservice = new SourceService;

const SourceDeleteButton = (props) => {
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  const deletesource = () => {
    setLoading(true);
    sourceservice.deleteSource(props.id)
      .then((response) => {
        setLoading(false);
        window.location.reload(false); // refresh page
      })
      .catch((error) => {
        console.log(error.response);
        setLoading(false);
        setConfirmation(false);
      })
  }

  function cancel() {
    setConfirmation(false);
  }

  function confirm() {
    setConfirmation(true);
  }

  if (loading) {
    return (<CircularProgress size="1rem" color="inherit" />);
  }
  else if (confirmation) {
    return (
      <ButtonGroup variant="contained" aria-label="outlined primary button group">
        <Button color="error" onClick={deletesource}>Confirm</Button>
        <Button onClick={cancel}><HighlightOffIcon /></Button>
      </ButtonGroup>
    );
  }
  else {
    return (
      <Button variant="contained" color="error" onClick={confirm} disabled={loading}>
        Delete
      </Button>
    );
  }

}


const SourceRow = (props) => {
  const navigate = useNavigate();
  const [source, setSource] = useState([]);
  const [activating, setActivating] = useState(false);

  useEffect(() => {
    setSource(props.source);
  }, []);

  function toggle() {
    setActivating(true);
    if (source.active) {
      sourceservice.disableSource(source.id).then((response) => {
        if (response.result) {
          setSource(prevState => ({
            ...prevState,
            active: false
          }));
          setActivating(false);
        }
      })
        .catch((error) => {
          setActivating(false);
        });
    }
    else {
      sourceservice.enableSource(source.id).then((response) => {
        if (response.result) {
          setSource(prevState => ({
            ...prevState,
            active: true
          }));
          setActivating(false);
        }
      })
        .catch((error) => {
          setActivating(false);
        });
    }
  }

  return (
    <TableRow
      key={"source-" + source.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        <Chip label={source.name} color="primary" />
      </TableCell>
      <TableCell align="left">{source.password}&nbsp;<IconButton onClick={() => { navigator.clipboard.writeText(source.password) }}><ContentPasteIcon /></IconButton></TableCell>
      <TableCell align="left">{activating ? <CircularProgress size="1rem" color="inherit" /> : <Switch checked={source.active} onClick={toggle} color="success" />}</TableCell>
      <TableCell align="left">
        <Button variant="contained" onClick={() => navigate("/caster/mountpoint/" + source.id)}>
          Edit
        </Button>
        <SourceDeleteButton id={source.id} /></TableCell>
    </TableRow>



  );
}

export default function SourceList() {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [showCreateForm, setShowCreateForm] = useState(false);

  useEffect(() => {
    setLoading(true);
    sourceservice.getSources().then((response) => {
      setRows(response);
      setLoading(false);
    });
  }, []);

  return (
    <React.Fragment>
      <h1 className="page-title"><span className="fw-semi-bold">Push-In Sources</span></h1>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Mountpoint</TableCell>
            <TableCell>Password</TableCell>
            <TableCell>Active</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading && rows.map((row, index) => <SourceRow key={row.id} source={row} />)}
        </TableBody>
      </Table>
      {loading && <LinearProgress speed={100} />}
      {!rows.length && !showCreateForm && (
        <Stack spacing={2} direction="row" alignItems="center">
          <Paper style={{ margin: "auto", marginTop: "2em", marginBottom: "2em", padding: "1em", width: "50%" }} >
            <Stack spacing={2} direction="column" alignItems="center">
              <p>No existing mountpoints</p>
              <Button variant="contained" onClick={() => setShowCreateForm(true)}>+ New Mountpoint</Button>
            </Stack>
          </Paper>
        </Stack>
      )}
      {showCreateForm &&
        <Stack direction="column" alignItems="center">
          <Paper style={{ marginTop: "2em", marginBottom: "2em", padding: "1em", minWidth: "15vw", maxWidth: "50vw" }} >
            <h3>Add Push-In Source</h3>
            <p>Push-In Sources allow other stations to push a correction stream into this base on a specified mountpoint. Push-In sources are also used to stream GNSS receivers connected by USB/serial.</p>
            <Stack spacing={2} direction="column" alignItems="center">
              <p>Adding a new push in source provides a mountpoint to push data to. It doesn't establish any connections. The mountpoint will be avialable once a source begins push data to the mountpoint.</p>
              <p>Use the form below to add a new mountpoint for push-in sources. A password is required to authenticate sources. If left blank a password will be generated automatically.</p>
              <SourceCreate />
            </Stack>
          </Paper>
        </Stack>}
      {rows.length > 0 && !showCreateForm && <Button variant="contained" onClick={() => setShowCreateForm(true)}>+ New Source</Button>}
    </React.Fragment >
  );
}
