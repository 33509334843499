import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import ReceiverService from '../receiverService';
import TextField from '@mui/material/TextField';
import { NoBackpackSharp } from '@mui/icons-material';
import { Typography } from '@mui/material';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import SendIcon from '@mui/icons-material/Send';


const receiverservice = new ReceiverService;

export default function GNSSTools() {
  return (
    <React.Fragment>
      <h1 className="page-title"><span className="fw-semi-bold">GNSS Tools</span></h1>
    <Stack spacing={2} direction="column">
      <ConvertRoverButton />
      <SendCommand />
    </Stack>
    </React.Fragment>
  );
}

const ConvertRoverButton = () => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState(false);
  const [message, setMessage] = useState("");

  const submit = () => {
    setLoading(true);
    receiverservice.convertToRover()
      .then((response) => {
        setLoading(false);
        setSubmitted(true);
        setResult(true);
      }).catch((error) => {
        setLoading(false);
        setSubmitted(true);
      })
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={submit} disabled={(loading || submitted)}>
        {!loading && !submitted && !result && <React.Fragment><ChangeCircleIcon />&nbsp;Convert to Rover</React.Fragment>}
        {loading && (<CircularProgress size="1rem" color="inherit" />)}
        {!loading && submitted && result && <CheckIcon color="success" />}
        {!loading && submitted && !result && <React.Fragment><ErrorOutlineIcon color="error" /><span>Error: Refresh and try again."</span></React.Fragment>}
      </Button>
      {!loading && submitted && result && <Typography>GNSS receiver is now in rover mode. You may remove the device and use as a rover. To use as a base again, power down the base, re-install the receiver and power the base on again. The base configuration will be automatically set.</Typography>}
    </React.Fragment>
  );
}

const SendCommand = () => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState("");

  const [input, setInput] = useState("");
  const [inputValid, setInputValid] = useState(true);

  const submit = () => {
    receiverservice.sendCommand(input)
      .then((response) => {
        setSubmitted(true);
        loading(false);
      });
  };

  const handleChange = (e) => {
    setInput(e.target.value);
    setInputValid(validateInput(input))
  };

  const validateInput = (input) => {
    if (/^\$/.test(input)) {
      return true;
    }
    return false;
  }

  return (
    <React.Fragment>
      <Stack spacing={2} direction="row">
        <TextField
          id="command"
          label="Command"
          onChange={handleChange}
          value={input}
          error={!inputValid}
          helperText={!inputValid ? "Invalid command format" : "Enter a receiver command."}
        />

        {submitted ? ("Done") : (
          <Button variant="contained" onClick={submit} disabled={(loading || submitted)}>
            {!submitted && <React.Fragment><SendIcon /></React.Fragment>}
            {loading && (<CircularProgress size="1rem" color="inherit" />)}
            {!loading && submitted && <CheckIcon color="success" />}
            {!loading && submitted && <ErrorOutlineIcon color="error" />}
          </Button>
        )}
      </Stack>
    </React.Fragment>
  );
}
