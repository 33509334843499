import axiosInstance from '../../util/axiosAPI';
import { API_URL } from '../../config';

export default class SourceService {

  constructor() { }

  getSources() {
    const url = `${API_URL}/api/ntrip/sources/`;
    return axiosInstance.get(url).then(response => response.data);
  }

  enableSource(id) {
    const url = `${API_URL}/api/ntrip/sources/${id}/enable/`;
    return axiosInstance.get(url).then(response => response.data);
  }

  disableSource(id) {
    const url = `${API_URL}/api/ntrip/sources/${id}/disable/`;
    return axiosInstance.get(url).then(response => response.data);
  }

  deleteSource(id) {
    const url = `${API_URL}/api/ntrip/sources/${id}/`;
    return axiosInstance.delete(url);
  }

  createSource(source) {
    const url = `${API_URL}/api/ntrip/sources/`;
    return axiosInstance.post(url, source);
  }

  updateSource(source) {
    const url = `${API_URL}/api/ntrip/sources/${source.id}/`;
    return axiosInstance.put(url, source);
  }

  partialUpdateSource(source) {
    const url = `${API_URL}/api/ntrip/sources/${source.id}/`;
    return axiosInstance.patch(url, source);
  }
}