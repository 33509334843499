import * as React from 'react';
import Stack from '@mui/material/Stack';
import GnssCoordinateList from './list';
import GnssCoordinateCreate from './create';
import { Typography } from '@mui/material';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import moment from 'moment';

export default function GnssCoordinateCurrent(props) {

  function round(
    value,
    minimumFractionDigits,
    maximumFractionDigits
  ) {
    const formattedValue = value.toLocaleString('en', {
      useGrouping: false,
      minimumFractionDigits,
      maximumFractionDigits
    })
    return Number(formattedValue)
  }

  return (
    <React.Fragment>
      <Stack spacing={2} direction="column" alignItems={"center"}>
        <box style={{
          display: "block",
          justifyContent: "center",
        }}>
          <Typography
            variant="h3"
            align='center'
          >
            {props.coordinate.latitude}
          </Typography>
          <Typography
            variant="h3"
            align='center'
          >
            {props.coordinate.longitude}
          </Typography>
          <Typography
            align='center'
          >
            <GpsFixedIcon sx={{ fontSize: "4em" }} />
          </Typography>
          <Typography
            variant="h5"
            align='center'
          >
            {props.coordinate.altitude} meters
          </Typography>
          <Typography
            variant="h5"
            align='center'
          >
            {round((props.coordinate.altitude * 3.28084), 3)} feet
          </Typography>
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="p"
            id="tableTitle"
            component="div"
          >
            Datum: {props.coordinate.datum}
          </Typography>
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="p"
            id="tableTitle"
            component="div"
          >
            Set by {props.coordinate.user} on {props.coordinate.datetime != null ? moment(props.coordinate.datetime, moment.ISO_8601).format("MM/DD/YYYY HH:mm:ss") : "None"}
          </Typography>
        </box>
      </Stack>
    </React.Fragment>
  );
}
