import axiosInstance from '../../util/axiosAPI';
import { API_URL } from '../../config';

export default class NetworkService {

    getNetwork() {
        const url = `${API_URL}/api/network/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    setNetwork(params) {
        const url = `${API_URL}/api/network/`;
        return axiosInstance.post(url, params);
    }

    uploadVpnConfig(form) {
        const url = `${API_URL}/api/network/vpn/`;
        return axiosInstance.post(url, form).then(response => response.data);
    }
}