import * as React from 'react';
import { useParams } from "react-router-dom";
import RTKServiceStatusWidget from './statusWidget';

export default function RTKServiceStatus() {
  let { id } = useParams();

    return (
      <RTKServiceStatusWidget id={id} />
    );
}