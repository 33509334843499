import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import ReceiverService from '../receiverService';
import SourceService from '../../sources/sourceService';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import { LinearProgress } from '@mui/material';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import RinexServiceApi from './service';
import Alert from '@mui/material/Alert';

const rinexservice = new RinexServiceApi();

export default function RINEXServiceForm() {
  let navigate = useNavigate();

  let { id } = useParams();

  const [loading, setLoading] = useState("");
  const [errors, setErrors] = useState({});

  const [device, setDevice] = useState("/dev/EOS_SERIAL");
  const [deviceValid, setDeviceValid] = useState(true);

  const [baudrate, setBaudrate] = useState("460800");

  // RINEX Header Values
  const [markerName, setMarkerName] = useState("AAAA");
  const [markerNameValid, setMarkerNameValid] = useState(true);

  const [markerNumber, setMarkerNumber] = useState(1);
  const [markerNumberValid, setMarkerNumberValid] = useState(true);

  const [observer, setObserver] = useState("");
  const [observerValid, setObserverValid] = useState(true);


  const [agency, setAgency] = useState("");
  const [agencyValid, setAgencyValid] = useState(true);

  const [antennaNumber, setAntennaNumber] = useState("HEMA45");
  const [antennaNumberValid, setAntennaNumberValid] = useState(true);

  const [antennaType, setAntennaType] = useState("NONE");
  const [antennaTypeValid, setAntennaTypeValid] = useState(true);

  // GNSS Recevier Config
  const [RTKGPS, setRTKGPS] = useState(true);
  const [RTKGLO, setRTKGLO] = useState(true);
  const [RTKGAL, setRTKGAL] = useState(true);
  const [RTKBDS, setRTKBDS] = useState(true);

  const [interval, setInterval] = useState(30);

  useEffect(() => {
    console.log("Service ID: " + id);
    populateForm();
  }, []);

  const populateForm = () => {
    setLoading(true);

    if(id) { // Populate form data if editing existing RTK service
      rinexservice.get(id).then((response) => {

        // Serial Settings
        setDevice(response.path);
        setBaudrate(response.baudrate);

        // GNSS Settings
        setRTKGPS(response.gps);
        setRTKGLO(response.glonass);
        setRTKGAL(response.galileo);
        setRTKBDS(response.beidou);
        setInterval(response.interval)

        // Header settings
        setMarkerName(response.header_marker_name);
        setMarkerNumber(response.header_marker_number);
        setObserver(response.header_observer);
        setAgency(response.header_agency);
        setAntennaNumber(response.header_antenna_num);
        setAntennaType(response.header_antenna_type);
      });
      setLoading(false);
    }
    else {
      setLoading(false);
    }

  }

  const handleDeviceChange = (event) => {
    let newDevice = cleanDevice(event.target.value);
    setDeviceValid(validateSerialPort(newDevice));
    setDevice(newDevice);
  };

  const handleMarkerNameChange = (event) => {
    let newMarkerName = cleanMarkerName(event.target.value);
    setMarkerName(newMarkerName);
    setMarkerNameValid(validateMarkerName(newMarkerName));
  };

  const handleMarkerNumberChange = (event) => {
    setMarkerNumber(event.target.value);
    setMarkerNumberValid(validateMarkerNumber(event.target.value));
  };

  const handleObserverChange = (event) => {
    setObserver(event.target.value);
    setObserverValid(validateObserver(event.target.value));
  };

  const handleAgencyChange = (event) => {
    setAgency(event.target.value);
    setAgencyValid(validateAgency(event.target.value));
  };

  const handleAntennaNumberChange = (event) => {
    let newAntennaNumber = cleanAntennaNumber(event.target.value);
    setAntennaNumber(newAntennaNumber);
    setAntennaNumberValid(validateAntennaNumber(newAntennaNumber));
  };

  const handleAntennaTypeChange = (event) => {
    let newAntennaType = cleanAntennaType(event.target.value);
    setAntennaType(newAntennaType);
    setAntennaTypeValid(validateAntennaType(newAntennaType));
  };

  function resetCasterValidation() {

  }


  const SubmitButton = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const submit = () => {
      setSubmitting(true);
      rinexservice.update(id, 
        {
          connection_type: "SERIAL",
          path: device,
          baudrate: baudrate,
          header_marker_name: markerName,
          header_marker_number: markerNumber,
          header_observer: observer,
          header_agency: agency,
          header_antenna_num: antennaNumber,
          header_antenna_type: antennaType,
          gps: RTKGPS,
          glonass: RTKGLO,
          galileo: RTKGAL,
          beidou: RTKBDS,
          interval: interval
        }).then((response) => {
          rinexservice.sync(id);
          setSubmitted(true);
          setSubmitting(false);
          navigate("/gnss/rinex/");
        }).catch((e) => {
          setSubmitting(false);
          setSubmitted(false);
          setErrors(e.response.data);
        });
    };
    return (
      <React.Fragment>
        <Button variant="contained" onClick={submit} disabled={(submitting || submitted)}>
          {!submitted && !submitting && "Save"}
          {submitting && (<CircularProgress size="1rem" color="inherit" />)}
          {!submitting && submitted && <CheckIcon color="success" />}
          {!submitting && submitted && <ErrorOutlineIcon color="error" />}
        </Button>
      </React.Fragment>
    );
  }

  const DeleteButton = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const deleteservice = () => {
      setSubmitting(true);
      rinexservice.delete(id).then((response) => {
          setSubmitted(true);
          setSubmitting(false);
          populateForm();
        });
    };
    return (
      <React.Fragment>
        <Button variant="contained" color="error" onClick={deleteservice} disabled={(submitting || submitted)}>
          {!submitted && !submitting && "Remove Service"}
          {submitting && (<CircularProgress size="1rem" color="inherit" />)}
          {!submitting && submitted && <CheckIcon color="success" />}
          {!submitting && submitted && <ErrorOutlineIcon color="error" />}
        </Button>
      </React.Fragment>
    );
  }

  if (loading) {
    return <LinearProgress />
  }
  else {
    return (
      <React.Fragment>
        <h1 className="page-title"><span className="fw-semi-bold">GNSS Connector</span></h1>
        <Stack>
          <Paper style={{ marginTop: "2em", marginBottom: "2em", padding: "1em", minWidth: "15vw" }} >
            <h3>{id ? "RINEX Logger" : "New RINEX Logger"}</h3>
            <Stack spacing={2}>
              <React.Fragment>
                <Typography>Log RINEX data continuously from the GNSS Receiver.</Typography>
                <h4>Serial Settings</h4>
                <TextField
                   id="device"
                   label="Device port"
                   onChange={handleDeviceChange}
                   value={device}
                   error={!deviceValid}
                   helperText={!deviceValid ? "Invalid device port" : "Enter a valid device port"}
                 />
                  {errors.hasOwnProperty("path") && (errors.path.map((error) => {
                      return <Alert severity="error">{error}</Alert>
                  }))}
               <Stack spacing={2}>
                 <FormControl>
                   <InputLabel id="baudrate">Baudrate</InputLabel>
                   <Select
                     labelId="baudrate"
                     id="baudrate-select"
                     value={baudrate}
                     label="baudrate"
                     onChange={(e) => setBaudrate(e.target.value)}
                     style={{ minWidth: "15em" }}
                   >
                       <MenuItem value={460800}>460800</MenuItem>
                       <MenuItem value={230400}>230400</MenuItem>
                       <MenuItem value={115200}>115200</MenuItem>
                       <MenuItem value={57600}>57600</MenuItem>
                       <MenuItem value={38400}>38400</MenuItem>
                       <MenuItem value={19200}>19200</MenuItem>
                       <MenuItem value={9600}>9600</MenuItem>
                       <MenuItem value={4800}>4800</MenuItem>
                   </Select>
                 </FormControl>
               </Stack>

                <h4>GNSS Settings</h4>
                <FormControl>
                  <FormLabel>Select Constellations</FormLabel>
                  <FormGroup row>
                    <FormControlLabel control={<Checkbox onClick={() => setRTKGPS(!RTKGPS)} checked={RTKGPS} />
                    } label="GPS" />
                    <FormControlLabel control={<Checkbox onClick={() => setRTKGLO(!RTKGLO)} checked={RTKGLO} />
                    } label="GLONASS" />
                    <FormControlLabel control={<Checkbox onClick={() => setRTKGAL(!RTKGAL)} checked={RTKGAL} />
                    } label="GALILEO" />
                    <FormControlLabel control={<Checkbox onClick={() => setRTKBDS(!RTKBDS)} checked={RTKBDS} />
                    } label="BeiDou" />
                  </FormGroup>
                </FormControl>
                <Stack spacing={2}>
                 <FormControl>
                   <InputLabel id="interval">Interval</InputLabel>
                   <Select
                     labelId="interval"
                     id="interval-select"
                     value={interval}
                     label="interval"
                     onChange={(e) => setInterval(e.target.value)}
                     style={{ minWidth: "15em" }}
                   >
                       <MenuItem value={1}>1 second</MenuItem>
                       <MenuItem value={5}>5 seconds</MenuItem>
                       <MenuItem value={10}>10 seconds</MenuItem>
                       <MenuItem value={30}>30 seconds</MenuItem>
                       <MenuItem value={60}>60 seconds</MenuItem>
                   </Select>
                 </FormControl>
               </Stack>
               <h4>RINEX Header</h4>
                <TextField
                  id="header-marker-name"
                  label="Marker Name"
                  onChange={handleMarkerNameChange}
                  value={markerName}
                  error={!markerNameValid}
                  helperText={!markerNameValid ? "Must be 4 characters." : "Enter a 4 character name (e.g. AAAA)."}
                />
                <TextField
                  id="header-marker-number"
                  label="Marker Number"
                  onChange={handleMarkerNumberChange}
                  value={markerNumber}
                  error={!markerNumberValid}
                  helperText={!markerNumberValid ? "Enter a reasonable integer..." : "Enter a marker number"}
                />
                <TextField
                  id="header-observer"
                  label="Observer"
                  onChange={handleObserverChange}
                  value={observer}
                  error={!observerValid}
                  helperText={!observerValid ? "No more than 20 characters allowed." : "Enter an observer name"}
                />
                <TextField
                  id="header-agency"
                  label="Agency"
                  onChange={handleAgencyChange}
                  value={agency}
                  error={!agencyValid}
                  helperText={!agencyValid ? "No more than 40 characters allowed." : "Enter an agency name"}
                />
                <TextField
                  id="header-antenna-number"
                  label="Antenna Number"
                  onChange={handleAntennaNumberChange}
                  value={antennaNumber}
                  error={!antennaNumberValid}
                  helperText={!antennaNumberValid ? "Invalid value." : "Enter an antenna number"}
                />
                <TextField
                  id="header-antenna-type"
                  label="Antenna Type"
                  onChange={handleAntennaTypeChange}
                  value={antennaType}
                  error={!antennaTypeValid}
                  helperText={!antennaTypeValid ? "Invalid value." : "Enter an antenna type"}
                />
              </React.Fragment>
              <SubmitButton />
              <DeleteButton />
              {id && 
                <Typography style={{color: "grey"}}>Service ID: {id}</Typography>
              }
            </Stack>
          </Paper>
        </Stack >
      </React.Fragment>
    );
  }
}


const cleanMarkerName = (value) => {
  return value.toUpperCase();
}

const validateMarkerName = (value) => {
  if (/^[A-Z0-9][A-Z0-9][A-Z0-9][A-Z0-9]$/.test(value)) {
    return true;
  }
  return false;
}

const validateMarkerNumber = (value) => {
  if (/^[0-9]*$/.test(value)) {
    if (value > 0 && value <= 2147483647) {
      return true;
    }
  }
  return false;
}

const validateObserver = (value) => {
  if (value.length <= 20) {
    return true;
  }
  return false;
}

const validateAgency = (value) => {
  if (value.length <= 40) {
    return true;
  }
  return false;
}

const cleanAntennaNumber = (value) => {
  return value.toUpperCase();
}

const validateAntennaNumber = (value) => {
  if (/^[A-Z0-9-_]*$/.test(value)) {
    return true;
  }
  return false;
}

const cleanAntennaType = (value) => {
  return value.toUpperCase();
}

const validateAntennaType = (value) => {
  if (/^[A-Z0-9]*$/.test(value)) {
    return true;
  }
  return false;
}

const validatePort = (port) => {
  if (/^[0-9][0-9]?[0-9]?[0-9]?[0-9]?$/.test(port)) {
    if (port > 0 && port <= 65535) {
      return true;
    }
  }
  return false;
}

const cleanInterval = (port) => {
  return port.replace(/[^0-9.]/g, '');
}

const cleanInteger = (port) => {
  return port.replace(/[^0-9.]/g, '');
}

const cleanDevice = (value) => {
  return value.replace(" ", "");
}

const validateSerialPort = (value) => {
  if (/[&\\#,+()$~%.'":*?<>{}]/.test(value)) {
    return false;
  }
  else if (value.length > 100) {
    return false;
  }
  else {
    return true;
  }
}

const validateRadioFreq = (value) => {
  if (/^[0-9]?[0-9]?[0-9]\.[0-9][0-9]?[0-9]?[0-9]?[0-9]?$/.test(value)) {
    return true;
  }
  return false;
}

const validateBroadcastId = (value) => {
  if (/^[0-9][0-9]?[0-9]?[0-9]?$/.test(value)) {
    if (value >= 0 && value <= 4096) {
      return true;
    }
  }
  return false;
}