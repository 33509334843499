import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import SourceService from './sourceService';


const sourceservice = new SourceService;

export default function SourceCreate() {
  let navigate = useNavigate();
  const [mountpoint, setMountpoint] = useState("");
  const [mountpointValid, setMountpointValid] = useState(true);

  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(true);

  const handleMountpointChange = (e) => {
    setMountpoint(cleanAlias(e.target.value));
    setMountpointValid(validateAlias(cleanAlias(e.target.value)));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordValid(validatePassword(e.target.value));
  };

  function formValid() {
    if (!mountpointValid) return false;
    if (!passwordValid) return false;
    return true;
  }


  const SubmitButton = () => {
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState("");

    const submit = () => {
      sourceservice.createSource(
        {
          name: mountpoint,
          password: password,
        }).then((response) => {
          setSubmitted(true);
          setLoading(false);
          window.location.reload(false); // refresh page
        })
        .catch((error) => {
          console.log(error.response.data.mountpoint[0]);
          setSubmitted(false);
          setLoading(false);
          setMessage(error.response.data.mountpoint[0]);
        });
    };

    if (loading) {
      return <CircularProgress size="1rem" color="inherit" />;
    }
    else if (submitted) {
      return (<Button onClick={navigate("/sources")} > Done</ Button>);
    }
    else {
      return (
        <React.Fragment>
          {message && <Alert severity="error">{message}</Alert>}
          <Button onClick={submit} disabled={!formValid()}>Save</Button>
        </React.Fragment>
      );
    }
  }

  return (
    <React.Fragment>
      <Stack fullWidth spacing={2} direction="column" alignItems="center">
        <TextField
          fullWidth
          id="mountpoint"
          label="Mountpoint name"
          onChange={handleMountpointChange}
          value={mountpoint}
          error={!mountpointValid}
          helperText={!mountpointValid ? "Invalid mountpoint name" : "Enter a valid mountpoint name."}
          autoFocus
        />

        <TextField
          fullWidth
          id="password"
          label="Password"
          onChange={handlePasswordChange}
          value={password}
          error={!passwordValid}
          helperText={!passwordValid ? "Check password." : "Enter a valid password."}
        />
        <SubmitButton />
      </Stack>
    </React.Fragment>)

}


const validateAlias = (alias) => {
  if (/[&\/\\#,+()$~%.'":*?<>{}]/.test(alias)) {
    return false;
  }
  else if (alias.length > 100) {
    return false;
  }
  else if (alias === "") {
    return false;
  }
  else {
    return true;
  }
}

const cleanAlias = (alias) => {
  return alias.replace(" ", '_');
}
// return alias.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '_');

const validatePassword = (password) => {
  if (password.length <= 100 && password.length >= 0) {
    return true;
  }
  return false;
}