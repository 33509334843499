import React from "react";
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import RelayService from '../relayService';
import ErrorIcon from '@mui/icons-material/Error';

const MountpointSelector = ({ip, port, selected, setSelected}) => {
  const relayservice = new RelayService;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;
  const [error, setError] = useState(false);
  const [inputValue, setInputValue] = useState(selected);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    // Fetch mountpoints
    relayservice.downloadMountpoints(ip, port).then((response) => {
      if (response.result === true) {
        setError(false);
        setOptions(response.mountpoints.map(mount => mount.name));
      }
      else {
        setError(true);
        setOpen(false);
      }
    });

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="mountpoint-select"
      autoSelect
      sx={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      noOptionsText="No mountpoints"
      isOptionEqualToValue={(option, value) => option === value}
      getOptionLabel={(option) => option}
      options={options}
      loading={loading}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        // if(!newInputValue) setInputValue('');
        // else {setInputValue(newInputValue)}
        setInputValue(newInputValue)
      }}
      value={selected}
      onChange={(event, newValue) => {
        setSelected(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Mountpoint"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {error ? <ErrorIcon color="error" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default MountpointSelector;