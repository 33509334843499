import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import NtripUserService from './service';
import MountpointService from '../../mountpoints/service';
import dayjs, { Dayjs } from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';

const userservice = new NtripUserService;
const mountservice = new MountpointService;

export default function NtripUserCreate() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [mountpoints, setMountpoints] = useState([]);
  const [mountsselected, setMountsSelected] = useState([]);

  useEffect(() => {
    setLoading(true);
    mountservice.list().then(mounts => {
      setMountpoints(mounts);
      setMountsSelected(mounts.map((mount) => { return mount.id })); // Auto select all mounts
      setLoading(false);
    });
  }, []);

  const [username, setUsername] = useState("");
  const [usernameValid, setUsernameValid] = useState(true);

  const [password, setPassword] = useState(RandomPassword(9));
  const [passwordValid, setPasswordValid] = useState(true);

  const [concurrent, setConcurrent] = useState(1);
  const [concurrentValid, setConcurrentValid] = useState(true);

  const [expiration, setExpiration] = React.useState(dayjs().add(1, 'year'));

  const handleUsernameChange = (e) => {
    setUsername(cleanUsername(e.target.value));
    setUsernameValid(validateUsername(cleanUsername(e.target.value)));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordValid(validatePassword(e.target.value));
  };

  const handleConcurrentChange = (e) => {
    setConcurrent(e.target.value);
  };

  const handleExpirationChange = (newDate) => {
    setExpiration(newDate);
  };

  const isMountSelected = (id) => mountsselected.indexOf(id) !== -1;

  const handleMountChecked = (id) => {
    if (mountsselected.includes(id)) {
      // Unselect
      const index = mountsselected.indexOf(id);
      setMountsSelected(mountsselected.filter(item => item !== id));
    }
    else {
      // Select
      setMountsSelected(prev => [...prev, id]);
    }
  };

  function RandomPassword(length) {
    let characters = "1234567890";
    var password = "";
    for (let i = 0; i < length; i++) {
      password += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return password;
  }

  function formValid() {
    if (!usernameValid) return false;
    if (!passwordValid) return false;
    return true;
  }


  const SubmitButton = () => {
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState("");

    const submit = () => {
      setLoading(true);
      userservice.create(
        {
          username: username,
          password: password,
          concurrent: concurrent,
          expiration: expiration.format('YYYY-MM-DD'),
          enabled: true,
          mountpoints: mountsselected
        }).then((response) => {
          setSubmitted(true);
          setLoading(false);
          window.location.reload(false); // refresh page
        })
        .catch((error) => {
          console.log(error.response.data.mountpoint[0]);
          setSubmitted(false);
          setLoading(false);
          setMessage(error.response.data.mountpoint[0]);
        });
    };

    if (loading) {
      return <CircularProgress size="1rem" color="inherit" />;
    }
    else if (submitted) {
      return (<Button onClick={navigate("/sources")} > Done</ Button>);
    }
    else {
      return (
        <React.Fragment>
          {message && <Alert severity="error">{message}</Alert>}
          <Button variant="contained" onClick={submit} disabled={!formValid()}>Save</Button>
        </React.Fragment>
      );
    }
  }

  if (loading) {
    return <LinearProgress />;
  }
  else {
  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack fullWidth spacing={2} direction="column" alignItems="center">
          <TextField
            fullWidth
            id="username"
            label="Username"
            onChange={handleUsernameChange}
            value={username}
            error={!usernameValid}
            helperText={!usernameValid ? "Invalid username" : "Enter a valid username."}
            autoFocus
          />

          <TextField
            fullWidth
            id="password"
            label="Password"
            onChange={handlePasswordChange}
            value={password}
            error={!passwordValid}
            helperText={!passwordValid ? "Check password." : "Enter a valid password."}
          />

          <TextField
            id="concurrent"
            label="Concurrent"
            type="number"
            onChange={handleConcurrentChange}
            value={concurrent}
            helperText={"Enter a number of concurrent connections allowed."}
          />

          <DesktopDatePicker
            label="Expiration Date"
            inputFormat="YYYY-MM-DD"
            value={expiration}
            onChange={handleExpirationChange}
            renderInput={(params) => <TextField {...params} />}
          />
          <Box sx={{ display: 'flex' }}>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormLabel>Mountpoint Access</FormLabel>
          <FormGroup>
              {mountpoints.map((mount) => {
                const isSelected = isMountSelected(mount.id);
                return <FormControlLabel key={mount.name} control={<Checkbox key={mount.name} onClick={() => handleMountChecked(mount.id)} checked={isSelected} />
              } label={mount.name} />;
            })}

          </FormGroup>
            </FormControl>
          </Box>
          <SubmitButton />
        </Stack>
      </LocalizationProvider>
      </React.Fragment>);
  }

}


const validateUsername = (username) => {
  if (/[&\/\\#,+()$~%'":*?<>{}]/.test(username)) {
    return false;
  }
  else if (username > 100) {
    return false;
  }
  else if (username === "") {
    return false;
  }
  else {
    return true;
  }
}

const cleanUsername = (username) => {
  return username.replace(" ", '');
}

const validatePassword = (password) => {
  if (password.length <= 100 && password.length >= 0) {
    return true;
  }
  return false;
}