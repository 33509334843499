import React, { useState, useEffect } from 'react';
import SystemService from './systemService';
import { CircularProgress, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { LinearProgress } from '@mui/material';
import BytesDisplay from '../../components/BytesDisplay';

const systemservice = new SystemService();

export default function About() {
    const [loading, setLoading] = useState(true);
    const [storage, setStorage] = useState({});

    useEffect(() => {
        setLoading(true);
        systemservice.storage().then((response) => {
            setStorage(response);
            setLoading(false);
        });
    }, []);

    return (
    <React.Fragment>
        <Paper style={{ marginTop: "2em", marginBottom: "2em", padding: "1em", width: "100%" }} >
            <Grid
            justifyContent="space-between"
            container
            direction="row"
            spacing={5}
            >
                <Grid item>
                    <h3>Software</h3>
                </Grid>
            </Grid>
            <br />
            <Grid
                justifyContent="space-between"
                container
                direction="row"
                spacing={2}
            >
                <Grid item>
                    <Typography>FastRTK Software Version: 5.1.0</Typography>
                    <Typography>Documentation: <a target="_blank" href="https://wiki.fastrtk.com/">https://wiki.fastrtk.com/</a></Typography>
                    <Typography>Discovery Management Group LLC 2023</Typography>
                </Grid>
            </Grid>
        </Paper>



        <Paper style={{ marginTop: "2em", marginBottom: "2em", padding: "1em", width: "100%" }} >
            <Grid
            justifyContent="space-between"
            container
            direction="row"
            spacing={5}
            >
                <Grid item>
                    <h3>Storage</h3>
                </Grid>
            </Grid>
            <br />
            <Grid
                justifyContent="space-between"
                container
                direction="row"
                spacing={2}
            >
                <Grid item>

                    {!loading ? (
                    <React.Fragment>
                        <h6>{Math.round((storage.used / storage.total) * 100)}% used</h6>
                        <LinearProgress 
                                variant="determinate" 
                                value={(storage.used / storage.total) * 100}
                                color="primary"
                                sx={{
                                    height: 6
                                }}
                            />
                        <br />
                        <Typography> Disk Size: <BytesDisplay bytes={storage.total} /></Typography>
                        <Typography> Used Space: <BytesDisplay bytes={storage.used}/> ({Math.round((storage.used / storage.total) * 100)}%)</Typography>
                        <Typography> Free Space: <BytesDisplay bytes={storage.free} /></Typography>
                    </React.Fragment>
                    ):(<CircularProgress />)}
                </Grid>
            </Grid>
        </Paper>
    </React.Fragment>
    );
}