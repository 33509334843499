import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ActivityService from './activityService';
import moment from 'moment';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import { LinearProgress, Tooltip } from '@mui/material';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import Grid from '@mui/material/Grid';

const service = new ActivityService;


export default function SourceActivityList(props) {
    const [page, setPage] = React.useState(null);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [nextUrl, setNextUrl] = React.useState(null);
    const [prevUrl, setPrevUrl] = React.useState(null);

    useEffect(() => {
        fetchData();
    }, []);


    const fetchData = () => {
        setLoading(true);
        service.listSourceActivity().then(response => {
            setPage(1);
            setRows(response.results);
            setNextUrl(response.next);
            setPrevUrl(response.previous);
            setLoading(false);
        });
    }

    const RefreshButton = () => {
        return (
            <Button onClick={() => fetchData()}>
                <RefreshIcon />
            </Button>
        );
    }

    const ClearButton = () => {
        const [loading, setLoading] = useState(false);

        const clearData = () => {
            setLoading(true);
            service.clear().then(response => {
                service.list().then(response => {
                    setRows(response);
                    setLoading(false);
                });
            });

        }

        return (
            <Button onClick={clearData} disabled={loading}>
                {loading && (<CircularProgress size="1rem" color="inherit" />)}
                {!loading && <ClearAllIcon />}
            </Button>
        );
    }

    const NextButton = () => {

        const nextData = () => {
            setLoading(true);
            service.getUrl(nextUrl).then(response => {
                setPage(page + 1);
                setRows(response.results);
                setNextUrl(response.next);
                setPrevUrl(response.previous);
                setLoading(false);
            });
        }

        return (
            <Button variant="contained" onClick={() => nextData()} disabled={!nextUrl}>
                Next
            </Button>
        );
    }

    const PreviousButton = () => {

        const prevData = () => {
            setLoading(true);
            service.getUrl(prevUrl).then(response => {
                setPage(page - 1);
                setRows(response.results);
                setNextUrl(response.next);
                setPrevUrl(response.previous);
                setLoading(false);
            });
        }

        return (
            <Button variant="contained" onClick={() => prevData()} disabled={!prevUrl}>
                Previous
            </Button>
        );
    }

    if (loading) return <LinearProgress />;

    else {
        return (
            <React.Fragment>
                <Tooltip title="Refresh">
                    <RefreshButton />
                </Tooltip>
                <Tooltip title="Clear all activiy">
                    <ClearButton />
                </Tooltip>
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Time</TableCell>
                                <TableCell align="left">Password</TableCell>
                                <TableCell align="left">Mountpoint</TableCell>
                                <TableCell align="left">Result</TableCell>
                                <TableCell align="left">Host</TableCell>
                                <TableCell align="left">Application</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {moment(row.datetime, moment.ISO_8601).format("MM/DD/YYYY hh:mm:ss")}
                                    </TableCell>
                                    <TableCell align="left">{row.password}</TableCell>
                                    <TableCell align="left">{row.mountpoint}</TableCell>
                                    <TableCell align="left">{row.authenticated ? <span style={{ color: "green" }}>Authorized</span> : <span style={{ color: "red" }}>Denied: {row.error}</span>}</TableCell>
                                    <TableCell align="left">{row.ip === "127.0.0.1" ? "Local GNSS Reciever" : row.ip}</TableCell>
                                    <TableCell align="left">{row.useragent}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Grid
                        justifyContent="space-between"
                        direction="row"
                        container
                        spacing={2}
                        marginTop={1}
                    >
                        <Grid item><PreviousButton /></Grid>
                        <Grid item>{page}</Grid>
                        <Grid item><NextButton /></Grid>
                    </Grid>
                    {loading && <LinearProgress />}
                </TableContainer>
            </React.Fragment>
        );
    }
}
