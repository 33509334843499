import axiosInstance from '../../util/axiosAPI';
import { API_URL } from '../../config';

export default class ReceiverService {

    constructor() { }

    setApproxCoordinate() {
        const url = `${API_URL}/api/gnss/controller/approxpos/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    getControllerSettings() {
        const url = `${API_URL}/api/gnss/settings/1/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    updateControllerSettings(settings) {
        const url = `${API_URL}/api/gnss/settings/1/`;
        return axiosInstance.patch(url, settings).then(response => response.data);
    }

    restartController() {
        const url = `${API_URL}/api/gnss/controller/restart/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    getCoordinates(data) {
        const url = `${API_URL}/api/gnss/coordinates/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    setCoordinate(data) {
        const url = `${API_URL}/api/gnss/coordinates/`;
        return axiosInstance.post(url, data).then(response => response.data);
    }

    deleteCoordinate(id) {
        const url = `${API_URL}/api/gnss/coordinates/${id}/`;
        return axiosInstance.delete(url).then(response => response.data);
    }

    receiverConfig() {
        const url = `${API_URL}/api/receiver/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    convertToRover() {
        const url = `${API_URL}/api/receiver/mode/rover/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    convertToBase() {
        const url = `${API_URL}/api/receiver/mode/base/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    sendCommand(command) {
        const url = `${API_URL}/api/receiver/command/`;
        return axiosInstance.post(url, { command: command }).then(response => response.data);
    }

    broadcastRadio() {
        const url = `${API_URL}/api/receiver/broadcast/`;
        return axiosInstance.post(url, { broadcast_mode: "radio" }).then(response => response.data);
    }

    broadcastNtrip() {
        const url = `${API_URL}/api/receiver/broadcast/`;
        return axiosInstance.post(url, { broadcast_mode: "ntrip" }).then(response => response.data);
    }
}