import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import ReceiverService from '../receiverService';
import SourceService from '../../sources/sourceService';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import { LinearProgress } from '@mui/material';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import RTKServiceApi from './service';

const sourceservice = new SourceService();
const rtkservice = new RTKServiceApi();

export default function RTKServiceForm({ id }) {
  let navigate = useNavigate();

  const [loading, setLoading] = useState("");
  const [localmounts, setLocalMounts] = useState([]);
  const [mountSelected, setMountSelected] = useState("");
  const [showForm, setShowForm] = useState(false);

  const [device, setDevice] = useState("");
  const [deviceValid, setDeviceValid] = useState(true);

  const [baudrate, setBaudrate] = useState(460800);

  const [caster, setCaster] = useState("");
  const [casterValid, setCasterValid] = useState(true);

  const [port, setPort] = useState(10000);
  const [portValid, setPortValid] = useState(true);

  const [username, setUsername] = useState("");

  const [password, setPassword] = useState("");

  const [mountpoint, setMountpoint] = useState("");
  const [mountpointValid, setMountpointValid] = useState(true);

  const [broadcastId, setBroadcastId] = useState(0);
  const [broadcastIdValid, setBroadcastIdValid] = useState(true);

  const [RTKProtocol, setRTKProtocol] = useState("RTCM3");

  const [RTKGPS, setRTKGPS] = useState(true);
  const [RTKGLO, setRTKGLO] = useState(true);
  const [RTKGAL, setRTKGAL] = useState(true);
  const [RTKBDS, setRTKBDS] = useState(true);


  // Radio Settings
  const [radioEnabled, setRadioEnabled] = useState(false);

  const [radioDevice, setRadioDevice] = useState("/dev/ttySC0");
  const [radioDeviceValid, setRadioDeviceValid] = useState(true);

  const [radioBaudrate, setRadioBaudrate] = useState(38400);

  const [radioFreq, setRadioFreq] = useState("438.00000");
  const [radioFreqValid, setRadioFreqValid] = useState(true);

  const [radioProtocol, setRadioProtocol] = useState("SATELLINE-3AS");

  const [radioTxPower, setRadioTxPower] = useState(2);

  const [radioChannelSpacing, setRadioChannelSpacing] = useState(12.5);

  useEffect(() => {
      populateForm();
  }, []);

  const populateForm = () => {
    setLoading(true);
    sourceservice.getSources().then((mounts) => {

      // Get available mountpoints
      setLocalMounts(mounts);

      if(id) { // Populate form data if editing existing RTK service
        rtkservice.get(id).then((service) => {
          // setLoading(false);
          if (service.casterip === "127.0.0.1") {
            let foundmount = mounts.find(element => element.name === service.mountpoint);
            setMountSelected(foundmount);
            setCaster("127.0.0.1");
            setPort("10000");
            setMountpoint(foundmount.name);
            setPassword(foundmount.password);
          }
          else {
            setMountSelected("Remote");
            setShowForm(true);
          }

          // Caster Settings
          setCaster(service.casterip);
          setPort(service.casterport);
          setUsername(service.user);
          setPassword(service.password);

          // Serial Settings
          setDevice(service.path);
          setBaudrate(service.baudrate);

          // GNSS Settings
          setRTKProtocol(service.protocol);
          setBroadcastId(service.broadcast_id);
          setRTKGPS(service.gps);
          setRTKGLO(service.glonass);
          setRTKGAL(service.galileo);
          setRTKBDS(service.beidou);

          // Radio settings
          setRadioEnabled(service.radio_enabled);
          setRadioDevice(service.radio_device);
          setRadioBaudrate(service.radio_baudrate);
          setRadioFreq(service.radio_frequency);
          setRadioProtocol(service.radio_protocol);
          setRadioTxPower(service.radio_tx_power);
          setRadioChannelSpacing(service.radio_channel_spacing);

        });
      }
      setLoading(false);
    });
  }

  const handleDeviceChange = (event) => {
    let newDevice = cleanDevice(event.target.value);
    setDeviceValid(validateSerialPort(newDevice));
    setDevice(newDevice);
  };

  const handleCasterChange = (e) => {
    setCaster(e.target.value);
    setCasterValid(validateCaster(e.target.value));
    resetCasterValidation();
  };

  const handlePortChange = (event) => {
    let newPort = cleanPort(event.target.value);
    setPort(newPort);
    setPortValid(validatePort(newPort));
    resetCasterValidation();
  };


  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    resetCasterValidation();
  };

  const handleMountpointChange = (event) => {
    let newMountpoint = cleanMountpoint(event.target.value);
    setMountpointValid(validateMountpoint(newMountpoint));
    setMountpoint(newMountpoint);
  };

  function resetCasterValidation() {

  }

  const handleChangeMountpoint = (event) => {
    if (event.target.value === "Remote") {
      setShowForm(true);
      setMountSelected(event.target.value);
      setCaster("");
      setPort("10000");
      setMountpoint("");
      setPassword("");
    }
    else {
      setShowForm(false);
      let mount = event.target.value;
      setMountSelected(mount);
      setCaster("127.0.0.1");
      setPort("10000");
      setMountpoint(mount.name);
      setPassword(mount.password);
    }
  };

  const handleBroadcastIdChange = (event) => {
    let newValue = cleanInteger(event.target.value);
    setBroadcastIdValid(validateBroadcastId(newValue));
    setBroadcastId(newValue);
  };

  const handleRadioDeviceChange = (event) => {
    let newDevice = cleanDevice(event.target.value);
    setRadioDeviceValid(validateSerialPort(newDevice));
    setRadioDevice(newDevice);
  };

  const handleRadioFreqChange = (event) => {
    let newValue = event.target.value;
    setRadioFreqValid(validateRadioFreq(newValue));
    setRadioFreq(newValue);
  };

  const SubmitButton = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState("");

    const submit = () => {
      setSubmitting(true);

      if(id) {

        rtkservice.update(id, 
          {
            connection_type: "SERIAL",
            path: device,
            baudrate: baudrate,
            casterip: caster,
            casterport: port,
            mountpoint: mountpoint,
            user: username,
            password: password,
            broadcast_id: broadcastId,
            outmode: 3,
            protocol: RTKProtocol,
            gps: RTKGPS,
            glonass: RTKGLO,
            galileo: RTKGAL,
            beidou: RTKBDS,
            radio_enabled: radioEnabled,
            radio_device: radioDevice,
            radio_baudrate: radioBaudrate,
            radio_frequency: radioFreq,
            radio_protocol: radioProtocol,
            radio_tx_power: radioTxPower,
            radio_channel_spacing: radioChannelSpacing
          }).then((response) => {
            rtkservice.sync(id);
            setSubmitted(true);
            setSubmitting(false);
            navigate("/gnss/rtk/");
          });
        }
        else {
          rtkservice.create( 
            {
              connection_type: "SERIAL",
              path: device,
              baudrate: baudrate,
              casterip: caster,
              casterport: port,
              mountpoint: mountpoint,
              user: username,
              password: password,
              broadcast_id: broadcastId,
              outmode: 3,
              protocol: RTKProtocol,
              gps: RTKGPS,
              glonass: RTKGLO,
              galileo: RTKGAL,
              beidou: RTKBDS,
              radio_enabled: radioEnabled,
              radio_device: radioDevice,
              radio_baudrate: radioBaudrate,
              radio_frequency: radioFreq,
              radio_protocol: radioProtocol,
              radio_tx_power: radioTxPower,
              radio_channel_spacing: radioChannelSpacing
            }).then((response) => {
              // receiverservice.restartController();
              setSubmitted(true);
              setSubmitting(false);
              navigate("/gnss/rtk/");
            });
        }
    };
    return (
      <React.Fragment>
        <Button variant="contained" onClick={submit} disabled={(submitting || submitted)}>
          {!submitted && !submitting && "Save"}
          {submitting && (<CircularProgress size="1rem" color="inherit" />)}
          {!submitting && submitted && <CheckIcon color="success" />}
          {!submitting && submitted && <ErrorOutlineIcon color="error" />}
        </Button>
      </React.Fragment>
    );
  }

  const DeleteButton = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState("");

    const deleteservice = () => {
      setSubmitting(true);
      rtkservice.delete(id).then((response) => {
          setSubmitted(true);
          setSubmitting(false);
          navigate("/gnss/rtk/");
        });
    };
    return (
      <React.Fragment>
        <Button variant="contained" color="error" onClick={deleteservice} disabled={(submitting || submitted)}>
          {!submitted && !submitting && "Remove Service"}
          {submitting && (<CircularProgress size="1rem" color="inherit" />)}
          {!submitting && submitted && <CheckIcon color="success" />}
          {!submitting && submitted && <ErrorOutlineIcon color="error" />}
        </Button>
      </React.Fragment>
    );
  }

  if (loading) {
    return <LinearProgress />
  }
  else {
    return (
      <React.Fragment>
        <h1 className="page-title"><span className="fw-semi-bold">GNSS Connector</span></h1>
        <Stack>
          <Paper style={{ marginTop: "2em", marginBottom: "2em", padding: "1em", minWidth: "15vw" }} >
            <h3>{id ? "RTK Stream" : "New RTK Stream"}</h3>
            <Stack spacing={2}>
              <React.Fragment>
                <Typography>Connect the GNSS Receiver to this base station's NTRIP Caster or a remote caster.</Typography>
                <h4>NTRIP Settings</h4>
                <Stack spacing={2} direction="row">
                  <FormControl disabled={localmounts.length < 1}>
                    <InputLabel id="mountpoint">Mountpoint</InputLabel>
                    <Select
                      labelId="mountpoint"
                      id="mountpoint-select"
                      value={mountSelected}
                      label="Mount name"
                      onChange={handleChangeMountpoint}
                      autoFocus
                     
                      style={{ minWidth: "15em" }}
                    >
                      {localmounts.map((mount, index) =>
                        <MenuItem value={mount}>{mount.name}{mount.identifier && (" - " + mount.identifier)}</MenuItem>
                      )}
                      <MenuItem value="Remote">(Remote Caster)</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>

                {showForm ? (
                    <Stack spacing={2}>
                      <TextField
                      
                        id="caster-ip"
                        label="Caster IP or Domain name"
                        onChange={handleCasterChange}
                        value={caster}
                        error={!casterValid}
                        helperText={!casterValid ? "Invalid caster address" : "Enter a valid dns name or IP."}
                        autoFocus
                      />
                      <TextField
                      
                        id="caster-port"
                        label="Caster Port"
                        onChange={handlePortChange}
                        value={port}
                        error={!portValid}
                        helperText={!portValid ? "Port number must be 1-63535." : "Enter a port number 1-63535."}
                      />
                      {/* <TextField
                    
                      id="username"
                      label="Username"
                      onChange={handleUsernameChange}
                      value={username}
                      error={!usernameValid}
                      helperText={!usernameValid ? "Check username." : "Enter a valid username."}
                    /> */}

                      <TextField
                      
                        id="password"
                        label="Password"
                        onChange={handlePasswordChange}
                        value={password}
                      />
                      <TextField
                      
                        id="mountpoint"
                        label="Mountpoint"
                        onChange={handleMountpointChange}
                        value={mountpoint}
                        error={!mountpointValid}
                        helperText={!mountpointValid ? "Invalid mountpoint name." : "Enter a valid mountpoint name."}
                      />
                    </Stack>
                ) : null}

                <h4>Serial Settings</h4>
                <TextField
                   id="device"
                   label="Device port"
                   onChange={handleDeviceChange}
                   value={device}
                   error={!deviceValid}
                   helperText={!deviceValid ? "Invalid device port" : "Enter a valid device port"}
                 />
               <Stack spacing={2}>
                 <FormControl>
                   <InputLabel id="baudrate">Baudrate</InputLabel>
                   <Select
                     labelId="baudrate"
                     id="baudrate-select"
                     value={baudrate}
                     label="baudrate"
                     onChange={(e) => setBaudrate(e.target.value)}
                     style={{ minWidth: "15em" }}
                   >
                       <MenuItem value={460800}>460800</MenuItem>
                       <MenuItem value={230400}>230400</MenuItem>
                       <MenuItem value={115200}>115200</MenuItem>
                       <MenuItem value={57600}>57600</MenuItem>
                       <MenuItem value={38400}>38400</MenuItem>
                       <MenuItem value={19200}>19200</MenuItem>
                       <MenuItem value={9600}>9600</MenuItem>
                       <MenuItem value={4800}>4800</MenuItem>
                   </Select>
                 </FormControl>
               </Stack>

                <h4>GNSS Settings</h4>
                <FormControl>
                    <InputLabel id="rtk-protocol">Protocol</InputLabel>
                    <Select
                      labelId="rtk-protocol"
                      id="rtk-protocol-select"
                      value={RTKProtocol}
                      label="Protocol"
                      onChange={(e) => setRTKProtocol(e.target.value)}
                    >
                        <MenuItem value="RTCM3">RTCM3</MenuItem>
                        <MenuItem value="ROX">ROX</MenuItem>
                        <MenuItem value="DFX">DFX</MenuItem>
                        <MenuItem value="CMR">CMR</MenuItem>
                    </Select>
                  </FormControl>
                <TextField
                  id="broadcast-id"
                  label="Broadcast ID"
                  onChange={handleBroadcastIdChange}
                  value={broadcastId}
                  error={!broadcastIdValid}
                  helperText={!broadcastIdValid ? "ID must be 0-4096." : "Enter a number 0-4096"}
                />
                <FormControl>
                  <FormLabel>Select Constellations</FormLabel>
                  <FormGroup row>
                    <FormControlLabel control={<Checkbox onClick={() => setRTKGPS(!RTKGPS)} checked={RTKGPS} />
                    } label="GPS" />
                    <FormControlLabel control={<Checkbox onClick={() => setRTKGLO(!RTKGLO)} checked={RTKGLO} />
                    } label="GLONASS" />
                    <FormControlLabel control={<Checkbox onClick={() => setRTKGAL(!RTKGAL)} checked={RTKGAL} />
                    } label="GALILEO" />
                    <FormControlLabel control={<Checkbox onClick={() => setRTKBDS(!RTKBDS)} checked={RTKBDS} />
                    } label="BeiDou" />
                  </FormGroup>
                </FormControl>
                
                <h4>Radio Settings</h4>
                <FormGroup>
                  <FormControlLabel sx={{color: (radioEnabled ? "green" : "red") }} control={<Checkbox onClick={() => setRadioEnabled(!radioEnabled)} checked={radioEnabled} />
                  } label={radioEnabled ? "Radio Enabled" : "Radio Disabled"} />
              </FormGroup>
              {radioEnabled ? (
                <Stack spacing={2}>
                  <TextField
                   
                    id="radio-device"
                    label="Device port"
                    onChange={handleRadioDeviceChange}
                    value={radioDevice}
                    error={!radioDeviceValid}
                    helperText={!radioDeviceValid ? "Invalid device port" : "Enter a valid device port"}
                  />
                <Stack spacing={2}>
                  <FormControl>
                    <InputLabel id="radio-baudrate">Baudrate</InputLabel>
                    <Select
                      labelId="radio-baudrate"
                      id="radio-baudrate-select"
                      value={radioBaudrate}
                      label="Baudrate"
                      onChange={(e) => setRadioBaudrate(e.target.value)}
                      autoFocus
                     
                      style={{ minWidth: "15em" }}
                    >
                        <MenuItem value={460800}>460800</MenuItem>
                        <MenuItem value={230400}>230400</MenuItem>
                        <MenuItem value={115200}>115200</MenuItem>
                        <MenuItem value={57600}>57600</MenuItem>
                        <MenuItem value={38400}>38400</MenuItem>
                        <MenuItem value={19200}>19200</MenuItem>
                        <MenuItem value={9600}>9600</MenuItem>
                        <MenuItem value={4800}>4800</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <TextField
                 
                  id="radio-freq"
                  label="Frequency"
                  onChange={handleRadioFreqChange}
                  value={radioFreq}
                  error={!radioFreqValid}
                  helperText={!radioFreqValid ? "Invalid frequency" : "Enter a valid frequency"}
                />
                <Stack spacing={2}>
                  <FormControl>
                    <InputLabel id="radio-protocol">Protocol</InputLabel>
                    <Select
                      labelId="radio-protocol"
                      id="radio-protocol-select"
                      value={radioProtocol}
                      label="Protocol"
                      onChange={(e) => setRadioProtocol(e.target.value)}
                      autoFocus
                     
                      style={{ minWidth: "15em" }}
                    >
                        <MenuItem value="SATELLINE-3AS">SATELLINE-3AS</MenuItem>
                        <MenuItem value="PacCrest-4FSK">PacCrest-4FSK</MenuItem>
                        <MenuItem value="PacCrest-GMSK">PacCrest-GMSK</MenuItem>
                        <MenuItem value="TrimTalk450s(P)">TrimTalk450s(P)</MenuItem>
                        <MenuItem value="Trimtalk450s(T)">Trimtalk450s(T)</MenuItem>
                        <MenuItem value="PacCrest-FST">PacCrest-FST</MenuItem>
                        <MenuItem value="2ASxE">2ASxE</MenuItem>
                        <MenuItem value="3AS-OA">3AS-OA (KAR)</MenuItem>
                        <MenuItem value="SOUTH">SOUTH</MenuItem>
                        <MenuItem value="SATEL-8FSK-1 (FEC OFF)">SATEL-8FSK-1 (FEC OFF)</MenuItem>
                        <MenuItem value="SATEL-8FSK-2 (FEC ON)">SATEL-8FSK-2 (FEC ON)</MenuItem>
                        <MenuItem value="SATEL-16FSK-1 (FEC ON)">SATEL-16FSK-1 (FEC ON)</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <Stack spacing={2}>
                  <FormControl>
                    <InputLabel id="radio-tx-power">Transmit Power (Watts)</InputLabel>
                    <Select
                      labelId="radio-tx-power"
                      id="radio-tx-power-select"
                      value={radioTxPower}
                      label="TX Power"
                      onChange={(e) => setRadioTxPower(e.target.value)}
                      autoFocus
                     
                      style={{ minWidth: "15em" }}
                    >
                        <MenuItem value={2}>2w</MenuItem>
                        <MenuItem value={10}>10w</MenuItem>
                        <MenuItem value={20}>20w</MenuItem>
                        <MenuItem value={25}>25w</MenuItem>
                        <MenuItem value={35}>35w</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>

                <Stack spacing={2}>
                  <FormControl>
                    <InputLabel id="radio-channel-spacing">Channel Spacing</InputLabel>
                    <Select
                      labelId="radio-channel-spacing"
                      id="radio-channel-spacing-select"
                      value={radioChannelSpacing}
                      label="Channel Spacing"
                      onChange={(e) => setRadioChannelSpacing(e.target.value)}
                      style={{ minWidth: "15em" }}
                    >
                        <MenuItem value={12.5}>12.5 kHz</MenuItem>
                        <MenuItem value={20}>20 kHz</MenuItem>
                        <MenuItem value={25}>25 kHz</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>

                </Stack>
                ):(
                  null
                )}
              </React.Fragment>
              <SubmitButton />
              <DeleteButton />
              <Typography style={{color: "grey"}}>Service ID: {id}</Typography>
            </Stack>
          </Paper>
        </Stack >
      </React.Fragment>
    );
  }
}



const validateCaster = (caster) => {
  if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(caster)) {
    return true;
  }
  else if (/^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/.test(caster)) {
    return true;
  }
  return false;
}

const validatePort = (port) => {
  if (/^[0-9][0-9]?[0-9]?[0-9]?[0-9]?$/.test(port)) {
    if (port > 0 && port <= 65535) {
      return true;
    }
  }
  return false;
}

const cleanPort = (port) => {
  return port.replace(/[^0-9.]/g, '');
}

const cleanInteger = (port) => {
  return port.replace(/[^0-9.]/g, '');
}

const validateMountpoint = (name) => {
  if (/[&/\\#,+()$~%.'":*?<>{}]/.test(name)) {
    return false;
  }
  else if (name.length > 100) {
    return false;
  }
  else {
    return true;
  }
}

const cleanMountpoint = (name) => {
  return name.replace(" ", '_');
}
// return alias.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '_');

const cleanDevice = (value) => {
  return value.replace(" ", "");
}

const validateSerialPort = (value) => {
  if (/[&#,+()$~%.'":*?<>{}]/.test(value)) {
    return false;
  }
  else if (value.length > 100) {
    return false;
  }
  else {
    return true;
  }
}

const validateRadioFreq = (value) => {
  if (/^[0-9]?[0-9]?[0-9]\.[0-9][0-9]?[0-9]?[0-9]?[0-9]?$/.test(value)) {
    return true;
  }
  return false;
}

const validateBroadcastId = (value) => {
  if (/^[0-9][0-9]?[0-9]?[0-9]?$/.test(value)) {
    if (value >= 0 && value <= 4096) {
      return true;
    }
  }
  return false;
}