import axiosInstance from '../../util/axiosAPI';
import { API_URL } from '../../config';

export default class CasterService {

    constructor() { }


    restart() {
        const url = `${API_URL}/api/ntrip/restart/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    rehash() {
        const url = `${API_URL}/api/ntrip/rehash/`;
        return axiosInstance.get(url).then(response => response.data);
    }

}