import React, { useState, useEffect } from 'react'
import { MapContainer, TileLayer, CircleMarker, useMap } from 'react-leaflet'

import 'leaflet/dist/leaflet.css';

export default function CoordinateMapView(props) {
  const [position, setPosition] = useState({ lat: 0.0, lng: 0.0 });
  const [highlight, setHighlight] = useState([0.0, 0.0]);

  useEffect(() => {
    setPosition({ lat: props.center[0], lng: props.center[1] });
  }, [props.center]);

  useEffect(() => {
    setHighlight(props.highlight);
  }, [props.highlight]);

  const Recenter = ({ lat, lng }) => {
    const map = useMap();
    useEffect(() => {
      map.setView([lat, lng], 15);
    }, [lat, lng]);
    return null;
  }

  return (
    <MapContainer
      maxZoom={30}
      style={{
        height: "50vh", borderRadius: "0px",
        width: "100%",
        minWidth: "100px",
      }}
    >
      <Recenter lat={position.lat} lng={position.lng} />
      <TileLayer
        // attribution='&copy <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {
        props.coordinates.map((coord, index) => {
          return <CircleMarker
            key={"ref-coord" + index}
            center={[coord.latitude, coord.longitude]}
            pathOptions={{ fillColor: 'black', color: 'black' }}
            radius={3}>
          </CircleMarker>
        })
      }
      <CircleMarker
        key="current-ref-coord"
        center={[props.coordinates[0].latitude, props.coordinates[0].longitude]}
        pathOptions={{ fillColor: 'green', color: 'green' }}
        radius={3}>
      </CircleMarker>

      {highlight != undefined &&
        <CircleMarker
          key="highlight-ref-coord"
          center={highlight}
          pathOptions={{ fillColor: '', color: 'yellow' }}
          radius={4}>
        </CircleMarker>
      }

    </ MapContainer >);
}