import React, { Component } from 'react';
import { Progress } from 'reactstrap';
import DataService from '../dataService';
import s from './data.module.scss';
import SdStorageIcon from '@mui/icons-material/SdStorage';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';


const dataService = new DataService();

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

class StorageStats extends Component {

    constructor(props) {
        super(props);
        this.state = {
            total: null,
            used: null,
        }

        this.getStorageStats = this.getStorageStats.bind(this);
    }

    componentDidMount() {
        this.getStorageStats();
        this.timer = setInterval(() => this.getStorageStats(), 60000);
    }


    componentWillUnmount() {
        clearInterval(this.timer);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
    }


    getStorageStats() {
        dataService.getStats().then(response => {
            this.setState({
                total: response.total,
                used: response.used
            })
        });
    }


    render() {

        return (
            <React.Fragment>
                <LinearProgressWithLabel style={{ maxWidth: "15em" }}
                    value={(((this.state.used / 1024 / 1024 / 1024) / (this.state.total / 1024 / 1024 / 1024)) * 100).toFixed(0)} />
                <p>
                    <SdStorageIcon />
                    <span className="fw-semi-bold">&nbsp;</span>
                    &nbsp;{(this.state.total / 1024 / 1024 / 1024).toPrecision(3) - (this.state.used / 1024 / 1024 / 1024).toPrecision(3)} GB Free
                </p>
            </React.Fragment>
        );

    }

}

export default StorageStats;

{/* <div className={[s.storage,'width-200 ml-2 text-center',].join(' ')}>
<h5>Storage Available</h5>
<Progress
    color="primary"
    value={this.state.used / this.state.total * 100}
    className="progress-sm mb-xs"
/>
<span>{(this.state.used / 1024 / 1024 / 1024).toPrecision(3)}GB / {(this.state.total / 1024 / 1024 / 1024).toPrecision(3)}GB</span>
</div> */}