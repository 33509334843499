import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Row, Col } from "reactstrap";
import Widget from "../../components/Widget/Widget";

import ActiveUsers from "./components/ActiveUsers";
import ActiveMounts from "./components/ActiveMounts";
import { WEBSOCKET_URL } from "../../config";
import Badge from '@mui/material/Badge';
import PeopleIcon from '@mui/icons-material/People';


import LiveMap from "./components/livemap/livemap-leaftlet";
import { SettingsInputAntenna } from '@mui/icons-material';

// const dashboardService = new DashboardService();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Dashboard(props) {
  const [value, setValue] = React.useState(0);

  const [activeMounts, setActiveMounts] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const UserWebSocket = useRef(null);
  const MountWebSocket = useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    UserWebSocket.current = new WebSocket(
      WEBSOCKET_URL
      + '/ws/'
      + 'users'
      + '/');

    UserWebSocket.onclose = function (e) {
      console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
      setTimeout(function () {
        UserWebSocket.current = new WebSocket(
          WEBSOCKET_URL
          + '/ws/'
          + 'users'
          + '/');
      }, 1000);
    };

    UserWebSocket.current.onmessage = function (e) {
      try {
        const data = JSON.parse(e.data);
        setActiveUsers(data);
      }
      catch { }

    };

    return () => UserWebSocket.current.close();

  }, []);


  useEffect(() => {
    MountWebSocket.current = new WebSocket(
      WEBSOCKET_URL
      + '/ws/'
      + 'mounts'
      + '/');


    MountWebSocket.current.onmessage = function (e) {
      try {
        const data = JSON.parse(e.data);
        setActiveMounts(data);
      }
      catch { }
    };

    return () => MountWebSocket.current.close();

  }, []);

  function MountsStatus() {

    const numActiveMounts = activeMounts.filter(function (mount) {
      return mount.connected;
    }).length;

    if (numActiveMounts === activeMounts.length) {

      return (
        <Badge badgeContent={numActiveMounts + "/" + activeMounts.length} color="success">
          <SettingsInputAntenna fontSize="large" color="action" />
        </Badge>
      )
    }
    else if (numActiveMounts > 0) {
      return (
        <Badge badgeContent={numActiveMounts + "/" + activeMounts.length} color="warning">
          <SettingsInputAntenna fontSize="large" color="action" />
        </Badge>
      )
    }
    else {
      return (
        <Badge badgeContent={numActiveMounts + "/" + activeMounts.length} color="error">
          <SettingsInputAntenna fontSize="large" color="action" />
        </Badge>
      )
    }
  }


  return (
    <React.Fragment>
      <Row style={{ position: "relative", top: "-20px" }}>
        <LiveMap mounts={activeMounts} users={activeUsers} />
      </Row>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} variant="fullWidth" centered>
            <Tab style={{ color: "black" }} label={<h5>Users&nbsp;</h5>} icon={<Badge badgeContent={(activeUsers && activeUsers.length) ? activeUsers.length : "0"} color="primary">
              <PeopleIcon fontSize="large" color="action" />
            </Badge>} iconPosition="end" {...a11yProps(0)} />
            <Tab style={{ color: "black" }} label={<h5>Mountpoints&nbsp;&nbsp;</h5>} icon={<MountsStatus />} color="primary" iconPosition="end" {...a11yProps(0)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ActiveUsers activeUsers={activeUsers} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ActiveMounts activeMounts={activeMounts} />
        </TabPanel>
      </Box>
    </React.Fragment>
  );
}
