import React from "react";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Grid from '@mui/material/Grid';

const DeleteByIdButton = ({ service, id, nextUrl, title}) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
  
    const preformDelete = () => {
      setLoading(true);
      service.delete(id)
        .then((response) => {
          setLoading(false);
          navigate(nextUrl);
        })
        .catch((error) => {
          console.log(error.response);
          setLoading(false);
          setConfirmation(false);
        })
    }
  
    function cancel() {
      setConfirmation(false);
    }
  
    function confirm() {
      setConfirmation(true);
    }
  
    if (loading) {
      return (<CircularProgress size="1rem" color="inherit" />);
    }
    else if (confirmation) {
      return (
        <Grid container spacing={1}>
          <Grid item>
            <Button variant="contained" onClick={cancel}>Cancel</Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="error" onClick={preformDelete}>Confirm</Button>
          </Grid>
        </Grid>
      );
    }
    else {
      return (
        <Button variant="contained" color="error" onClick={confirm} disabled={loading}>
          {title ? title : "Delete"}
        </Button>
      );
    }
  
  }

  export default DeleteByIdButton;