import axiosInstance from '../../../util/axiosAPI';
import { API_URL } from '../../../config';

export default class RTKServiceApi {

    constructor() { }

    async list() {
        const url = `${API_URL}/api/gnss/rtk/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    async get(id) {
        const url = `${API_URL}/api/gnss/rtk/${id}/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    async create(data) {
        const url = `${API_URL}/api/gnss/rtk/`;
        return axiosInstance.post(url, data).then(response => response.data);
    }

    async delete(id) {
        const url = `${API_URL}/api/gnss/rtk/${id}/`;
        return axiosInstance.delete(url).then(response => response.data);
    }

    async update(id, data) {
        const url = `${API_URL}/api/gnss/rtk/${id}/`;
        return axiosInstance.patch(url, data).then(response => response.data);  
    }


    async start(id) {
        const url = `${API_URL}/api/gnss/rtk/${id}/start/`;
        return axiosInstance.get(url).then(response => response);
    }

    async stop(id) {
        const url = `${API_URL}/api/gnss/rtk/${id}/stop/`;
        return axiosInstance.get(url).then(response => response);
    }

    async sync(id) {
        const url = `${API_URL}/api/gnss/rtk/${id}/sync/`;
        return axiosInstance.get(url).then(response => response);
    }

    async status(id) {
        const url = `${API_URL}/api/gnss/rtk/${id}/status/`;
        return axiosInstance.get(url).then(response => response);
    }

}