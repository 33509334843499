import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Adminservice from './service';
import MountpointService from '../../mountpoints/service';
import dayjs, { Dayjs } from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AdminUsersService from './service';

const service = new AdminUsersService;

export default function AdminUserCreate(props) {
  let navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [usernameValid, setUsernameValid] = useState(true);

  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(true);

  const handleUsernameChange = (e) => {
    setUsername(cleanUsername(e.target.value));
    setUsernameValid(validateUsername(cleanUsername(e.target.value)));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordValid(validatePassword(e.target.value));
  };

  function formValid() {
    if (!usernameValid) return false;
    if (!passwordValid) return false;
    return true;
  }


  const SubmitButton = (props) => {
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState("");

    const submit = () => {
      setLoading(true);
      service.create(
        {
          username: username,
          password: password,
        }).then((response) => {
          setSubmitted(true);
          setLoading(false);
          props.refresh();
        })
        .catch((error) => {
          console.log(error.response.data.user[0]);
          setSubmitted(false);
          setLoading(false);
          setMessage(error.response.data.user[0]);
        });
    };

    if (loading) {
      return <CircularProgress size="1rem" color="inherit" />;
    }
    else if (submitted) {
      // navigate("/users/admin");
    }
    else {
      return (
        <React.Fragment>
          {message && <Alert severity="error">{message}</Alert>}
          <Button onClick={submit} disabled={!formValid()}>Save</Button>
        </React.Fragment>
      );
    }
  }

  return (
    <React.Fragment>
      <Stack fullWidth spacing={2} direction="column" alignItems="center">
        <TextField
          fullWidth
          id="username"
          label="Username"
          onChange={handleUsernameChange}
          value={username}
          error={!usernameValid}
          helperText={!usernameValid ? "Invalid username" : "Enter a valid username."}
          autoFocus
        />

        <TextField
          fullWidth
          id="password"
          label="Password"
          type="password"
          onChange={handlePasswordChange}
          value={password}
          error={!passwordValid}
          helperText={!passwordValid ? "Check password." : "Enter a valid password."}
        />
        <SubmitButton refresh={() => props.refresh()} />
      </Stack>
    </React.Fragment>)

}


const validateUsername = (username) => {
  if (/[&\/\\#,+()$~%'":*?<>{}]/.test(username)) {
    return false;
  }
  else if (username > 100) {
    return false;
  }
  else if (username === "") {
    return false;
  }
  else {
    return true;
  }
}

const cleanUsername = (username) => {
  return username.replace(" ", '');
}

const validatePassword = (password) => {
  if (password.length <= 100 && password.length >= 0) {
    return true;
  }
  return false;
}