import axiosInstance from '../../../util/axiosAPI';
import { API_URL } from '../../../config';

export default class AdminUsersService {

    constructor() { }

    list() {
        const url = `${API_URL}/api/users/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    get(id) {
        const url = `${API_URL}/api/users/${id}/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    delete(id) {
        const url = `${API_URL}/api/users/${id}/`;
        return axiosInstance.delete(url);
    }

    create(user) {
        const url = `${API_URL}/api/users/`;
        return axiosInstance.post(url, user);
    }

    passwordReset(user) {
        const url = `${API_URL}/api/auth/passwordreset/`;
        return axiosInstance.post(url, user);
    }

    update(user) {
        const url = `${API_URL}/api/users/${user.id}/`;
        return axiosInstance.patch(url, user);
    }
}
