import * as React from 'react';
import { useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { LinearProgress } from '@mui/material';
import Button from '@mui/material/Button';
import BytesDisplay from '../../../../components/BytesDisplay';
import TextField from '@mui/material/TextField';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DoneIcon from '@mui/icons-material/Done';

import DataService from '../dataService';
import { Download } from '@mui/icons-material';
import { API_URL } from '../../../../config';
import axiosInstance from '../../../../util/axiosAPI';

const dataService = new DataService();

const DownloadButton = ({file, size}) => {
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [bytesDown, setBytesDown] = useState(0);
    const [error, setError] = useState(false);

    const link = document.createElement("a");
    link.target = "_blank";
    link.download = file.filename;
    const controller = new AbortController();

    function cancelDownload() {
      setError(false);
      setLoading(false);
      setProgress(0);
      setBytesDown(0);
      controller.abort();
    }

    function handleDownload() {
        setError(false);
        setLoading(true);
        setProgress(0);
        setBytesDown(0);

        const url = `${API_URL}/api/data/download?year=${file.year}&day=${file.day}&file=${file.filename}`;
        return axiosInstance.get(
            url, { responseType: 'blob',
                   timeout: 0, // 2 hours
                   signal: controller.signal,
                   onDownloadProgress: (progressEvent) => {
                   let totalSize = file.size;
                   let percentCompleted = (progressEvent.loaded * 100) / totalSize;
                   setProgress(percentCompleted);
                   setBytesDown(progressEvent.loaded);
                  }
        },
        ).then(res => {
            link.href = URL.createObjectURL(
                new Blob([res.data], { type: "text/plain" })
              );
            link.click();
            setLoading(false);
        }).catch((error) => {
          setError(true);
        });
    }

    if(loading) {
      if(progress <= 0) {
        return <Box sx={{ width: '100%', color: "grey.500" }}><LinearProgress /></Box>;
      }
      else {
        // return <CircularProgressWithLabel size={25} value={progress} />
        // return <Button variant='outlined' style={{background: 'linear-gradient(90deg, rgba(36,0,0,1) 40%, rgba(255,0,0,1) 41%);' }}> 34% </Button>
        //return <Button fullWidth variant='outlined' disabled style={{ background: `linear-gradient(90deg, rgba(24,118,209,1) ${progress}%, rgba(255,255,255,0.8) ${progress}%)` }}> {Math.round((progress + Number.EPSILON) * 100) / 100}% </Button>
        return <Grid container alignItems="center" style={{height: "100%", width: "100%"}}>
                    <Grid item xs={10} style={{height: "100%"}}>
                    <Tooltip title={`${Math.round(((bytesDown / 1000000) + Number.EPSILON) * 100) / 100}MB  ${Math.round((progress + Number.EPSILON) * 100) / 100}%`}>
                        <LinearProgress fullWidth variant="determinate" value={progress} />
                    </Tooltip>
                    </Grid>
                    <Grid item xs={2}>
                      <Tooltip title="Cancel">
                        <Button fullWidth onClick={cancelDownload}>
                          <HighlightOffIcon color="error" />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
      }
    }
    else if(error) {
      return <Button variant="outlined" color="warning" onClick={handleDownload}>Network error</Button>
    }
    else if(progress === 100) {
      return <Grid container spacing={0.5} alignItems="center" style={{height: "100%", width: "100%"}}>
      <Grid item xs={10} style={{height: "100%"}}>
          <LinearProgress fullWidth variant="determinate" color="success" value={progress} />
      </Grid>
      <Grid item xs={2}>
            <DoneIcon color="success" />
      </Grid>
    </Grid>
    }
    else {
        return <Button variant="outlined" onClick={handleDownload}>Download</Button>
    }
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'filename',
    numeric: false,
    disablePadding: true,
    label: 'Filename',
  },
  {
    id: 'size',
    numeric: false,
    disablePadding: true,
    label: 'Size',
  },
  {
    id: 'file-download',
    numeric: false,
    disablePadding: true,
    label: '',
  }
];

const DEFAULT_ORDER = 'asc';
const DEFAULT_ORDER_BY = 'filename';
const DEFAULT_ROWS_PER_PAGE = 25;

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (newOrderBy) => (event) => {
    onRequestSort(event, newOrderBy);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all files',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          GNSS Data Files
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function EnhancedTable({rows}) {
  const [order, setOrder] = React.useState(DEFAULT_ORDER);
  const [orderBy, setOrderBy] = React.useState(DEFAULT_ORDER_BY);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [visibleRows, setVisibleRows] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
  const [paddingHeight, setPaddingHeight] = React.useState(0);

  React.useEffect(() => {
    let rowsOnMount = stableSort(
      rows,
      getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY),
    );

    rowsOnMount = rowsOnMount.slice(
      0 * DEFAULT_ROWS_PER_PAGE,
      0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE,
    );

    setVisibleRows(rowsOnMount);
  }, []);
  

  const handleRequestSort = React.useCallback(
    (event, newOrderBy) => {
      const isAsc = orderBy === newOrderBy && order === 'asc';
      const toggledOrder = isAsc ? 'desc' : 'asc';
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);

      const sortedRows = stableSort(rows, getComparator(toggledOrder, newOrderBy));
      const updatedRows = sortedRows.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      );

      setVisibleRows(updatedRows);
    },
    [order, orderBy, page, rowsPerPage],
  );

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.filename);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = React.useCallback(
    (event, newPage) => {
      setPage(newPage);

      const sortedRows = stableSort(rows, getComparator(order, orderBy));
      const updatedRows = sortedRows.slice(
        newPage * rowsPerPage,
        newPage * rowsPerPage + rowsPerPage,
      );

      setVisibleRows(updatedRows);

      // Avoid a layout jump when reaching the last page with empty rows.
      const numEmptyRows =
        newPage > 0 ? Math.max(0, (1 + newPage) * rowsPerPage - rows.length) : 0;

      const newPaddingHeight = (dense ? 33 : 53) * numEmptyRows;
      setPaddingHeight(newPaddingHeight);
    },
    [order, orderBy, dense, rowsPerPage],
  );

  const handleChangeRowsPerPage = React.useCallback(
    (event) => {
      const updatedRowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(updatedRowsPerPage);

      setPage(0);

      const sortedRows = stableSort(rows, getComparator(order, orderBy));
      const updatedRows = sortedRows.slice(
        0 * updatedRowsPerPage,
        0 * updatedRowsPerPage + updatedRowsPerPage,
      );

      setVisibleRows(updatedRows);

      // There is no layout jump to handle on the first page.
      setPaddingHeight(0);
    },
    [order, orderBy],
  );

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table
            // sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            {/* <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            /> */}
            <TableBody>
              {visibleRows
                ? visibleRows.map((row, index) => {
                    const isItemSelected = isSelected(row.filename);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.filename)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.filename}
                        selected={isItemSelected}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell padding="checkbox">
                          {/* <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                            onClick={(event) => handleClick(event, row.filename)}
                          /> */}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.filename}
                        </TableCell>
                        <TableCell align="left"><BytesDisplay bytes={row.size} /></TableCell>
                        <TableCell align="center"><DownloadButton file={row}/></TableCell>
                      </TableRow>
                    );
                  })
                : null}
                {rows.length > 0 ? null : <TableRow><TableCell colSpan={6}>No files found in date range.</TableCell></TableRow>}
              {paddingHeight > 0 && (
                <TableRow
                  style={{
                    height: paddingHeight,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}

function QueryTool({range, setRange, sendquery}) {

    return (
        <Grid container spacing={0.5} rowSpacing={1}>
            <Grid item>
                <TextField 
                    value={range.start.year} 
                    onChange={(e) => setRange(
                        {
                            ...range, 
                            start: {...range.start, year: e.target.value},
                            end: {...range.end, year: e.target.value}
                        })
                    } 
                    label="Start Year" 
                    variant="outlined"
                    inputProps={{ maxLength: 4, size: 8 }}
                    size="small"
                />
            </Grid>
            <Grid item>
            <TextField 
                    value={range.start.day} 
                    onChange={(e) => setRange(
                        {
                            ...range, 
                            start: {...range.start, day: e.target.value},
                            end: {...range.end, day: e.target.value}
                        })
                    } 
                    label="Start Day" 
                    variant="outlined"
                    inputProps={{ maxLength: 3, size: 8 }}
                    size="small"
                />
            </Grid>
            <Grid item>
                <ArrowForwardIcon />
            </Grid>
            <Grid item>
            <TextField 
                    value={range.end.year} 
                    onChange={(e) => setRange(
                        {
                            ...range, 
                            end: {...range.end, year: e.target.value}
                        })
                    } 
                    label="End Year" 
                    variant="outlined"
                    inputProps={{ maxLength: 4, size: 8 }}
                    size="small"
                    error={Number(range.end.year) < Number(range.start.year)}
                />
            </Grid>
            <Grid item>
            <TextField 
                    value={range.end.day} 
                    onChange={(e) => setRange(
                        {
                            ...range, 
                            end: {...range.end, day: e.target.value}
                        })
                    } 
                    label="End Day" 
                    variant="outlined"
                    inputProps={{ maxLength: 3, size: 8 }}
                    size="small"
                    error={(Number(range.end.year) === Number(range.start.year) && Number(range.end.day) < Number(range.start.day))}
                />
            </Grid>
        </Grid>
    );
}


function SelectFiletype({filetype, setFiletype}) {
    const [age, setAge] = React.useState('');
  
    const handleChange = (event) => {
      setFiletype(event.target.value);
    };
  
    return (
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="filetype-select-label">File type</InputLabel>
          <Select
            labelId="filetype-select-label"
            id="filetype-select"
            value={filetype}
            label="Type"
            onChange={handleChange}
            size="small"
          >
            <MenuItem value="rinex">RINEX</MenuItem>
            <MenuItem value="nav">Navigation</MenuItem>
            <MenuItem value="bin">Binary</MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
  }

export default function FilesTable() {
    const [loading, setLoading] = React.useState(true);
    const [rows, setRows] = React.useState([]);

    const [period, setPeriod] = React.useState({
        start: {
            year: "",
            day: ""
        },
        end: {
            year: "",
            day: ""
        }
    });

    const [filetype, setFiletype] = React.useState("");

    React.useEffect((e) => {
        fetchData();
    }, []);

    const checkEnterPress = useCallback((e) => {
      const { key, keyCode } = e;
      if (keyCode === 13) {
        if(!loading) {
          fetchData();
        }
      }
    },[period]);

    useEffect(() => {
      window.addEventListener("keydown", checkEnterPress);
      return () => {
        window.removeEventListener("keydown", checkEnterPress);
      };
    }, [checkEnterPress]);


    function fetchData() {
        setLoading(true);
        dataService.files(period, filetype).then(response => {
            setRows(response.files);
            setPeriod(response.period);
            setFiletype(response.filetype);
            setLoading(false);
        });
    }



    return (
        <React.Fragment>
                              <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Select Date Range
        </Typography>
        <br />
            <Grid container spacing={0.5} rowSpacing={1}>
                <Grid item>
                    <QueryTool range={period} setRange={setPeriod} />
                </Grid>
                <Grid item>
                    <SelectFiletype filetype={filetype} setFiletype={setFiletype} />
                </Grid>
                <Grid item>
                  <Button variant='contained' onClick={fetchData}>Query</Button>
                </Grid>
            </Grid>
            <br />
            {loading ? <LinearProgress /> : <EnhancedTable rows={rows} />}
        </React.Fragment>
    );
}