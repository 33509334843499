import axios from "axios";
import { API_URL } from "../config";
import { memoizedRefreshToken } from "./refreshToken";

// Create axios instance & set defaults
const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 60000
});

// Inject access token into auth header
axiosInstance.interceptors.request.use(
  async (config) => {
    const session = JSON.parse(localStorage.getItem("session"));

    if (session?.access_token) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${session?.access_token}`,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);


// Automatically refresh token
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;
    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;

      const result = await memoizedRefreshToken();

      if (result?.access_token) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${result?.access_token}`,
        };
      }

      return axiosInstance(config);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;