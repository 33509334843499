import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { CircularProgress, LinearProgress } from '@mui/material';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import { WEBSOCKET_URL } from "../../../config";
import RTKServiceApi from './service';
import { SettingsEthernet } from '@mui/icons-material';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import CircleIcon from '@mui/icons-material/Circle';
import BytesDisplay from '../../../components/BytesDisplay';

function fancyTimeFormat(duration) {
  // Hours, minutes and seconds
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;

  return ret;
}

const rtkservice = new RTKServiceApi();

export default function RTKServiceStatusWidget(props) {
  let navigate = useNavigate();
  const id = props.id;
  const StatusWebSocket = useRef(null);

  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState();
  const [status, setStatus] = useState({});

  const [oldBytesStreamed, setOldBytesStreamed] = useState(0);
  const [progress, setProgress] = useState(0);
  const [increment, setIncrement] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);
     rtkservice.get(id).then(data => {
      setSettings(data);
      setLoading(false);
    });
  }

  useEffect(() => {
    if(progress > 100 ) setProgress(progress - 100);
  }, [progress]);
  
  useEffect(() => {
    // Calculate new progress bar value
    if(status.hasOwnProperty('bytes_streamed') && status.bytes_streamed) {

      if(oldBytesStreamed === 0) {
        setIncrement(0);
      }
      else {
        setIncrement((Number(status.bytes_streamed) - Number(oldBytesStreamed)) / 100);
      }

      setProgress(progress + increment);
      setOldBytesStreamed(status.bytes_streamed);

    }
  
  }, [status.bytes_streamed]);

  useEffect(() => {
    StatusWebSocket.current = new WebSocket(
      WEBSOCKET_URL
      + '/ws/'
      + 'gnss'
      + '/'
      + id + '/');

      StatusWebSocket.onclose = function (e) {
      console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
      setTimeout(function () {
        StatusWebSocket.current = new WebSocket(
          WEBSOCKET_URL
          + '/ws/'
          + 'gnss'
          + '/'
          + id + '/');
      }, 1000);
    };

    StatusWebSocket.current.onmessage = function (e) {
      try {
        const data = JSON.parse(e.data);

        // Update status object with new data
        setStatus(data);

      }
      catch { }

    };

    return () => StatusWebSocket.current.close();

  }, []);

  if (loading) {
    return <LinearProgress />
  }
  else {
    return (
      <Paper style={{ marginTop: "2em", marginBottom: "2em", padding: "1em", width: "100%" }} >
          <Grid
            justifyContent="space-between"
            container
            direction="row"
            spacing={5}
          >
            <Grid item>
              <h3>{status.running ? <CircleIcon color='success' /> : <CircleIcon color='error' />} <b>{settings.mountpoint}</b> <SettingsEthernet /> {settings.path}</h3>
            </Grid>
          </Grid>
          <br />
          <Grid
            justifyContent="space-between"
            container
            direction="row"
            spacing={2}
          >
            <Grid item>
            <h5>Data Stream (bytes)</h5>
              <Typography>From Device: <BytesDisplay bytes={status.bytes_read} /></Typography>
              <Typography>To Caster: <BytesDisplay bytes={status.bytes_streamed} /></Typography>
              {settings.radio_enabled ? <Typography>To Radio: <BytesDisplay bytes={status.radio_bytes_sent} /></Typography> : null}
              {increment > 0 ? <Typography style={{color: "green"}} >+<BytesDisplay bytes={increment} /></Typography> : <Typography color="gray"><BytesDisplay bytes={increment} /></Typography>}
            </Grid>
            <Grid item>
            <h5>Run Time</h5>
              {status.running ? (
                <Typography style={{color: "green"}}>{fancyTimeFormat(status.run_time_secs)}</Typography>
              ):(
                <Typography style={{color: "red"}}>{fancyTimeFormat(status.run_time_secs)}</Typography>
              )}
            </Grid>
            <Grid item>
            <h5>NTRIP Caster</h5>
              <Typography>Server: {status.hasOwnProperty('ntrip') ? status.ntrip.server : "-"}</Typography>
              <Typography>Port: {status.hasOwnProperty('ntrip') ? status.ntrip.port : "-"}</Typography>
              <Typography>Mountpoint: {status.hasOwnProperty('ntrip') ? status.ntrip.mountpoint : "-"}</Typography>
              <Typography>User: {status.hasOwnProperty('ntrip') ? status.ntrip.user : "-"}</Typography>
              <Typography>Password: {status.hasOwnProperty('ntrip') ? status.ntrip.password : "-"}</Typography>
            </Grid>
            <Grid item>
              <h5>Reference Coordinate</h5>
              <Typography>Latitude: {status.hasOwnProperty('gnss') ? status.gnss.coordinate.latitude : "-"}</Typography>
              <Typography>Longitude: {status.hasOwnProperty('gnss') ? status.gnss.coordinate.longitude : "-"}</Typography>
              <Typography>Altitude: {status.hasOwnProperty('gnss') ? status.gnss.coordinate.altitude : "-"}</Typography>
            </Grid>
            <Grid item>
            <h5>Broadcast Settings</h5>
              <Typography>Protocol: {settings.protocol}</Typography>
              <Typography>Broadcast ID: {status.hasOwnProperty('gnss') ? status.gnss.broadcastid : "-"}</Typography>
              {settings.protocol === "RTCM3" && 
                <Typography>RTCM Messages: {status.hasOwnProperty('gnss') ? status.gnss.rtcm_messages : "-"}</Typography>
              }
              <Typography>Constellations: {settings.gps && <span style={{color: "blue"}}>GPS</span>} {settings.glonass && <span style={{color: "green"}}>GLONASS</span>} {settings.galileo && <span style={{color: "red"}}>GALILEO</span>} {settings.beidou && <span style={{color: "gold"}}>BeiDou</span>}</Typography>
            </Grid>
            <Grid item>
              <h5>Device Connection</h5>
              <Typography>Serial Port: {status.hasOwnProperty('gnss') ? status.gnss.path : "-"}</Typography>
              <Typography>Baudrate: {status.hasOwnProperty('gnss') ? status.gnss.baudrate : "-"}</Typography>
            </Grid>
            <Grid item>
            <h5>GNSS Reciever Info</h5>
              <Typography>Serial Number: {status.hasOwnProperty('gnss') ? status.gnss.serialnumber : "-"}</Typography>
              <Typography>Firmware: {status.hasOwnProperty('gnss') ? status.gnss.firmwareversion : "-"}</Typography>
              <Typography>Hardware: {status.hasOwnProperty('gnss') ? status.gnss.hardwareversion : "-"}</Typography>
              <Typography>Activations: {status.hasOwnProperty('gnss') ? status.gnss.options : "-"}</Typography>
            </Grid>

            <Grid item>
            <h5>Radio Info</h5>
            {settings.radio_enabled ? (
              <React.Fragment>
                <Typography>Port: {status.hasOwnProperty('radio') ? status.radio.baudrate : "-"}</Typography>
                <Typography>Firmware: {status.hasOwnProperty('radio') ? status.radio.firmwareversion : "-"}</Typography>
                <Typography>Frequency: {status.hasOwnProperty('radio') ? status.radio.frequency : "-"}</Typography>
                <Typography>Transmit Power (w): {status.hasOwnProperty('radio') ? status.radio.txpower : "-"}</Typography>
                <Typography>Channel Spacing: {status.hasOwnProperty('radio') ? status.radio.channelspacing : "-"}</Typography>
                <Typography>Protocol: {status.hasOwnProperty('radio') ? status.radio.protocol : "-"}</Typography>
                <Typography>Model: {status.hasOwnProperty('radio') ? status.radio.model : "-"}</Typography>
                <Typography>S/N: {status.hasOwnProperty('radio') ? status.radio.serialnumber : "-"}</Typography>
                <Typography>Hardware Version: {status.hasOwnProperty('radio') ? status.radio.hardwareversion : "-"}</Typography>
              </React.Fragment>
            ):(
              <Typography color="red">Disabled</Typography>
            )}

            </Grid>
          </Grid>
          <br />

        {status.running ? (
          <LinearProgress 
            variant="determinate" 
            value={progress}
            color="success"
            sx={{
              height: 6
            }}
        />
        ) : (
          <LinearProgress 
          variant="determinate" 
          value={1}
          color="error"
          sx={{
            height: 6
          }}
      />
        )}
        <br />
        <Typography color="gray">Status: {status.message}</Typography>
        <br />

        {loading ? <LinearProgress /> :(
          <React.Fragment>
            <IconButton aria-label="Start" disabled={status.running} onClick={() => {rtkservice.start(settings.id); setOldBytesStreamed(0);}}>
              <PlayCircleFilledIcon color="success" />
            </IconButton>
            <IconButton aria-label="Stop" disabled={status.running === false} onClick={() => {rtkservice.stop(settings.id); setOldBytesStreamed(0);}}>
              <StopCircleIcon color="error" />
            </IconButton>
            <IconButton aria-label="Restart" onClick={()=>rtkservice.sync(settings.id)}>
              <ReplayCircleFilledIcon color="" />
            </IconButton>

            
            <Button style={{float: "right" }} variant="contained" onClick={()=>navigate("/gnss/rtk/" + id + "/edit")}><SettingsIcon /> Settings</ Button>
          </React.Fragment>
        )}
        <Typography style={{color: "grey"}}>Service ID: {id}</Typography>
      </Paper>
    );
  }
}