import axiosInstance from '../../util/axiosAPI';
import { API_URL } from '../../config';

export default class RelayService {

  constructor() { }

  getRelays() {
    const url = `${API_URL}/api/ntrip/relays/`;
    return axiosInstance.get(url).then(response => response.data);
  }

  get(id) {
    const url = `${API_URL}/api/ntrip/relays/${id}/`;
    return axiosInstance.get(url).then(response => response.data);
  }

  enableRelay(id) {
    const url = `${API_URL}/api/ntrip/relays/${id}/enable/`;
    return axiosInstance.get(url).then(response => response.data);
  }

  disableRelay(id) {
    const url = `${API_URL}/api/ntrip/relays/${id}/disable/`;
    return axiosInstance.get(url).then(response => response.data);
  }

  validateRelay(id) {
    const url = `${API_URL}/api/ntrip/relays/${id}/validate/`;
    return axiosInstance.get(url).then(response => response.data);
  }

  validateNewRelay(relay) {
    const url = `${API_URL}/api/ntrip/testclient/`;
    return axiosInstance.post(url, relay).then(response => response.data);
  }

  delete(id) {
    const url = `${API_URL}/api/ntrip/relays/${id}/`;
    return axiosInstance.delete(url);
  }

  createRelay(relay) {
    const url = `${API_URL}/api/ntrip/relays/`;
    return axiosInstance.post(url, relay);
  }

  put(relay) {
    const url = `${API_URL}/api/ntrip/relays/${relay.id}/`;
    return axiosInstance.put(url, relay);
  }

  patch(relay) {
    const url = `${API_URL}/api/ntrip/relays/${relay.id}/`;
    return axiosInstance.patch(url, relay);
  }

  downloadMountpoints(caster, port) {
    const url = `${API_URL}/api/ntrip/downloadmounts/`;
    return axiosInstance.post(url, { server: caster, port: port }).then(response => response.data);
  }
}