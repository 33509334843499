import React, { useState, useEffect } from 'react'
import { MapContainer, TileLayer, CircleMarker, Polyline, Tooltip, ZoomControl } from 'react-leaflet'
import { Progress } from "reactstrap";

import 'leaflet/dist/leaflet.css';
import { Alert, LinearProgress } from '@mui/material';

const baselineOptions = {
  color: 'black',
  weight: '1',
  opacity: '75',
  dashArray: '10, 10',
  dashOffset: '20'
}

function getExtent(coordsArray) {
  var maxLat = 0;
  var minLat = 0;
  var maxLong = 0;
  var minLong = 0;

  coordsArray.forEach((coord, index) => {

    if (index === 0) {
      minLat = coord[0];
      maxLat = coord[0];
      minLong = coord[1];
      maxLong = coord[1];
    }

    if (Math.abs(-90 - coord[0]) < Math.abs(-90 - minLat)) minLat = coord[0];
    if (Math.abs(90 - coord[0]) < Math.abs(90 - maxLat)) maxLat = coord[0];
    if (Math.abs(-180 - coord[1]) < Math.abs(-180 - minLong)) minLong = coord[1];
    if (Math.abs(180 - coord[1]) < Math.abs(180 - maxLong)) maxLong = coord[1];
  })

  return [
    [maxLat, minLong],
    [maxLat, maxLong],
    [minLat, maxLong],
    [minLat, minLong]
  ]

}

function getCenter(coordsArray) {
  var maxLat = 0;
  var minLat = 0;
  var maxLong = 0;
  var minLong = 0;

  coordsArray.forEach((coord, index) => {

    if (index === 0) {
      minLat = coord[0];
      maxLat = coord[0];
      minLong = coord[1];
      maxLong = coord[1];
    }

    if (Math.abs(-90 - coord[0]) < Math.abs(-90 - minLat)) minLat = coord[0];
    if (Math.abs(90 - coord[0]) < Math.abs(90 - maxLat)) maxLat = coord[0];
    if (Math.abs(-180 - coord[1]) < Math.abs(-180 - minLong)) minLong = coord[1];
    if (Math.abs(180 - coord[1]) < Math.abs(180 - maxLong)) maxLong = coord[1];
  })

  return [(maxLat + minLat) / 2, (maxLong + minLong) / 2]

}

function getCoordsArray(users, mounts) {
  let usersCoords = [];
  let mountsCoords = [];
  let coordsArray = [];
  if (users && users.length > 0) {
    usersCoords = users.map(function (user) { return [user.location.latitude, user.location.longitude] });
  }
  if (mounts && mounts.length > 0) {
    mountsCoords = mounts.map(function (mount) { return [mount.location.latitude, mount.location.longitude] });
  }
  return coordsArray.concat(mountsCoords, usersCoords);
}



function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1);  // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return Number.parseFloat(d).toFixed(2);
}

function deg2rad(deg) {
  return deg * (Math.PI / 180)
}

function Mount(mount) {
  var mountstyle = {}
  if (mount.connected) {
    mountstyle = { fillColor: 'green', color: 'green' }
  }
  else {
    mountstyle = { fillColor: 'red', color: 'red' }
  }

  return <CircleMarker key={mount.name} center={[mount.location.latitude, mount.location.longitude]} pathOptions={mountstyle} radius={7}>
    <Tooltip>
      <ul>
        <li key="name">Mount: {mount.name}</li>
        <li key="status">Status: {mount.connected ? "Online" : "Offline"}<br /></li>
        <li key="users">Connected Users: {mount.clients}</li>
        <li key="type">Type: {mount.type}</li>
        <li key="time">Time: {mount.time}</li>
        <li key="bytes">Bytes: {(mount.bytes < 1000000) ? <span> {Math.round((mount.bytes * 0.001 + Number.EPSILON) * 100) / 100} KB</span> : <span>{Math.round((mount.bytes * 0.000001 + Number.EPSILON) * 100) / 100} MB</span>}</li>
      </ul>
    </Tooltip>
  </CircleMarker>;

}

function User(user, mount) {

  const FixTypes = {
    4: "RTK Fixed",
    5: "RTK Float",
    2: "DGPS",
    1: "Autonomous",
    0: "No position"
  }

  var userstyle = { weight: 2 }
  if (
    user &&
    user.location &&
    user.location.latitude != null &&
    user.location.longitude != null &&
    user.location.latitude !== 0 &&
    user.location.longitude !== 0
  ) {
    if (mount && mount.location && mount.location.latitude != null && mount.location.longitude != null) {

      // Set fill color based on nearest
      userstyle.fillOpacity = 1;
      if (user.nearest) userstyle.color = 'orange';
      else {
        userstyle.color = 'black';
      }

      // Set halo color based on fix type
      if (user.fixtype === 4) userstyle.fillColor = 'green'; // RTK Fixed
      else if (user.fixtype === 5) userstyle.fillColor = 'orange'; // RTK Float
      else userstyle.color = 'yellow'; // Not using RTK

      let baselinekm = getDistanceFromLatLonInKm(
        mount.location.latitude,
        mount.location.longitude,
        user.location.latitude,
        user.location.longitude);

      let baselinemi = Number.parseFloat((baselinekm * 0.621371)).toFixed(2);;

      return (
        <CircleMarker key={user.USER} center={[user.location.latitude, user.location.longitude]} pathOptions={userstyle} radius={5} eventHandlers={{
          click: () => {
            // map.setView(
            //   [
            //     marker.geometry.coordinates[1],
            //     marker.geometry.coordinates[0]
            //   ],
            //   14
            // );
            alert(user.USER + " clicked!")
          }
        }}>
          <Tooltip>
            <ul>
              <li key="user">User: {user.USER}</li>
              <li key="mount">Mount: {mount.name}</li>
              <li key="fix">Fix: {FixTypes[user.fixtype]}</li>
              <li key="sats">Sats: {user.sats}</li>
              <li key="baseline">Baseline: {baselinekm}km / {baselinemi}mi</li>
              <li key="ntripclient">NTRIP Client: {user.USERAGENT}</li>
              <li key="ip">{user.IP}</li>
              <li key="bytes">Bytes: {(user.BYTES < 1000000) ? <span> {Math.round((user.BYTES * 0.001 + Number.EPSILON) * 100) / 100} KB</span> : <span>{Math.round((user.BYTES * 0.000001 + Number.EPSILON) * 100) / 100} MB</span>}</li>
              <li key="duration">Duration: {user.TIME}</li>
            </ul>
          </Tooltip>
        </CircleMarker>
      )
    }
  }
}

function LiveMap(props) {
  const [center, setCenter] = useState([40.0, -100.0]);
  const [zoom, setZoom] = useState(4);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // let coordsArray = getCoordsArray(props.users, props.mounts);
    // setCenter(getCenter(coordsArray));
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <LinearProgress />
    );
  }
  else {
    return (
      <MapContainer
        center={center}
        zoom={zoom}
        maxZoom={30}
        style={{
          height: "50vh", borderRadius: "0px"
        }}
      >
        <TileLayer
          // attribution='&copy <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {props.users.map((user, index) => {
          let currentmount = props.mounts.find(mount => mount.name === user.MOUNT);
          if (currentmount && currentmount.location &&
            currentmount.location.latitude != null &&
            currentmount.location.longitude != null &&
            user && user.location &&
            user.location.latitude != null &&
            user.location.longitude != null &&
            user.location.latitude !== 0 &&
            user.location.longitude !== 0) {
            const userbaseline = [
              [currentmount.location.latitude, currentmount.location.longitude],
              [user.location.latitude, user.location.longitude],
            ]
            return <Polyline key={index} positions={userbaseline} options={baselineOptions} />;
          }
        })}

        {props.mounts && props.mounts.length > 0 && props.mounts.map((mount) => Mount(mount))}
        {props.users && props.users.length > 0 && props.users.map((user) => User(user, props.mounts.find((mount) => mount.name === user.MOUNT)))}


        {/* <CircleMarker center={getCenter(getCoordsArray(props.users, props.mounts))} pathOptions={{ color: 'black' }} radius={4}>
          <Tooltip>Center point</Tooltip>
        </CircleMarker > */}

        {/* <Progress animated value={100}>Waiting for data...</Progress> */}

      </ MapContainer >);
  }
}

export default LiveMap;