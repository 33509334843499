import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { NoBackpackSharp } from '@mui/icons-material';
import { Typography } from '@mui/material';
import CasterService from './casterService';
import SyncIcon from '@mui/icons-material/Sync';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const casterservice = new CasterService;

export default function CasterTools() {
  return (
    <React.Fragment>
      <h1 className="page-title"><span className="fw-semi-bold">NTRIP Caster Tools</span></h1>
    <Stack spacing={2} direction="column">
        <Typography>
          Sync caster with database. Use this function if the NTRIP caster is not picking up changes after database altering actions.
        </Typography>
      <RehashButton />
        <Typography>
          Restart NTRIP caster. Use this function if a hard reset of the caster is needed.
        </Typography>
      <RestartButton />
    </Stack>
    </React.Fragment>
  );
}

const RehashButton = () => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState(false);
  const [message, setMessage] = useState("");

  const submit = () => {
    setLoading(true);
    casterservice.rehash()
      .then((response) => {
        setLoading(false);
        setSubmitted(true);
        setResult(true);
      }).catch((error) => {
        setLoading(false);
        setSubmitted(true);
      })
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={submit} disabled={(loading || submitted)}>
        {!loading && !submitted && !result && <React.Fragment><SyncIcon />&nbsp;Sync Caster</React.Fragment>}
        {loading && (<CircularProgress size="1rem" color="inherit" />)}
        {!loading && submitted && result && <CheckIcon color="success" />}
        {!loading && submitted && !result && <React.Fragment><ErrorOutlineIcon color="error" /><span>Error: Refresh and try again."</span></React.Fragment>}
      </Button>
    </React.Fragment>
  );
}


const RestartButton = () => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState(false);
  const [message, setMessage] = useState("");

  const submit = () => {
    setLoading(true);
    casterservice.restart()
      .then((response) => {
        setLoading(false);
        setSubmitted(true);
        setResult(true);
      }).catch((error) => {
        setLoading(false);
        setSubmitted(true);
      })
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={submit} disabled={(loading || submitted)}>
        {!loading && !submitted && !result && <React.Fragment><RestartAltIcon />&nbsp;Restart Caster</React.Fragment>}
        {loading && (<CircularProgress size="1rem" color="inherit" />)}
        {!loading && submitted && result && <CheckIcon color="success" />}
        {!loading && submitted && !result && <React.Fragment><ErrorOutlineIcon color="error" /><span>Error: Refresh and try again."</span></React.Fragment>}
      </Button>
    </React.Fragment>
  );
}
