import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import { NoBackpackSharp } from '@mui/icons-material';
import { Typography } from '@mui/material';
import SystemService from './systemService';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';


const systemservice = new SystemService;

export default function SystemTools() {
  return (
    <Stack spacing={2} direction="column">
      <RestartButton />
    </Stack>
  );
}

const RestartButton = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState(false);
  const [message, setMessage] = useState("");
  const [restarting, setRestarting] = useState(false);

  const submit = () => {
    setLoading(true);
    systemservice.restart()
      .then((response) => {
        setLoading(false);
        setSubmitted(true);
        setResult(true);
        setRestarting(true);
        setTimeout(function () { navigate("/") }, 30000);
      }).catch((error) => {
        setLoading(false);
        setSubmitted(true);
      })
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={submit} disabled={(loading || submitted)}>
        {!loading && !submitted && !result && <React.Fragment><PowerSettingsNewIcon />&nbsp;Restart</React.Fragment>}
        {loading && (<CircularProgress size="1rem" color="inherit" />)}
        {!loading && submitted && result && <CheckIcon color="success" />}
        {!loading && submitted && !result && <React.Fragment><ErrorOutlineIcon color="error" /><span>Error: Refresh and try again."</span></React.Fragment>}
      </Button>
      {restarting && (
        <React.Fragment>
          <Typography>Rebooting...</Typography>
          <br />
          <LinearProgress /></React.Fragment>
      )}
    </React.Fragment>
  );
}
