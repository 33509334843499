import axios from "axios";
import mem from "mem";
import { API_URL } from "../config";
import qs from 'qs';

const refreshToken = async () => {
    const stored_session = JSON.parse(localStorage.getItem("session"));
  
    try {

        const axiosdata = { 
            'grant_type': 'refresh_token',
            'refresh_token': stored_session?.refresh_token,
            'client_id': 'PSgWb4jCwrdUFZVMaKABgRLvLj0JmPRbpWNlsick',
            'client_secret': '9oWTUW8w0XE351TeRqIRVPU9zjZp0F1I0XRyNFdFWcik6EsApnfD0RLOMuHDHAAAiefVJrlFtKKkciHGZiJbnJmx2cgTP0JmiEtAITnLIndcmutQ8N6oPJjqqnyc06JQ',
        };

        const options = {
            method: 'POST',
            data: qs.stringify(axiosdata),
            url: API_URL + '/api/o/token/',
            timeout: 10000
        };

            
      const response = await axios(options);
  
      const session = response.data;
  
      if (!session?.access_token) {
        localStorage.removeItem("session");
        localStorage.removeItem("user");
      }
  
      localStorage.setItem("session", JSON.stringify(session));
  
      return session;
    } catch (error) {
      localStorage.removeItem("session");
      localStorage.removeItem("user");
    }
  };
  
  const maxAge = 10000;
  
  export const memoizedRefreshToken = mem(refreshToken, {
    maxAge,
  });