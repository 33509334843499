import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import RelayService from './relayService';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';

const relayservice = new RelayService();

export default function RelayCreate(props) {
  let navigate = useNavigate();
  const [caster, setCaster] = useState("");
  const [casterValid, setCasterValid] = useState(true);

  const [port, setPort] = useState(10000);
  const [portValid, setPortValid] = useState(true);

  const [username, setUsername] = useState("");
  const [usernameValid, setUsernameValid] = useState(true);

  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(true);

  const [alias, setAlias] = useState("");
  const [aliasValid, setAliasValid] = useState(true);

  const [dip, setDip] = useState(false);

  const [mountSelection, setMountSelection] = useState([]);
  const [mountSelected, setMountSelected] = useState("");
  const [montsLoading, setMountsLoading] = useState(false);
  const [downloadError, setDownloadError] = useState("");

  const [relayValidated, setRelayValidated] = useState(false);
  const [relayChecked, setRelayChecked] = useState(false);
  const [relayValidatedError, setRelayValidatedError] = useState("");


  const handleChangeMountpoint = (value) => {
    setMountSelected(value);
    setAlias(value);
    resetCasterValidation();
  };

  const handleCasterChange = (e) => {
    setCaster(e.target.value);
    setCasterValid(validateCaster(e.target.value));
    resetCasterValidation();
    clearMountpointList();
  };

  const handlePortChange = (event) => {
    let newPort = cleanPort(event.target.value);
    setPort(Number(newPort));
    setPortValid(validatePort(newPort));
    resetCasterValidation();
    clearMountpointList();
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    resetCasterValidation();
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    resetCasterValidation();
  };

  const handleAliasChange = (event) => {
    let newAlias = cleanAlias(event.target.value);
    setAliasValid(validateAlias(newAlias));
    setAlias(newAlias);
  };

  function resetCasterValidation() {
    setRelayChecked(false);
    setRelayValidated(false);
    setRelayValidatedError("");
  }

  function clearMountpointList() {
    setMountSelection([]);
    setMountSelected("");
    setDownloadError("");
    setMountsLoading(false);
  }

  const GetMountpointsButton = () => {
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);

    const getMountpoints = () => {
      setDownloadError("");
      setLoading(true);
      relayservice.downloadMountpoints(caster, port).then((response) => {
        setLoading(false);
        if (response.result === true) {
          setMountSelection(response.mountpoints);
          setMountSelected(response.mountpoints[0].name);
        }
        else {
          setDownloadError(response.message);
        }
      });
    };
    return (
      <Button variant="contained" style={{ minWidth: "15em" }} onClick={getMountpoints} disabled={loading || !caster || !casterValid || !portValid}>
        {!checked && !loading && "Download Mountpoints"}
        {loading && (<CircularProgress size="1rem" color="inherit" />)}
        {!loading && checked && <CheckIcon color="success" />}
        {!loading && checked && <ErrorOutlineIcon color="error" />}
      </Button>
    );
  }

  const ValidateRelayButton = () => {
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [checked, setChecked] = useState(false);
    const [message, setMessage] = useState("");

    const validaterelay = () => {
      setLoading(true);
      resetCasterValidation();
      relayservice.validateNewRelay(
        {
          server: caster,
          port: port,
          username: username,
          password: password,
          name: mountSelected,
          dip: dip
        }).then((response) => {
          setLoading(false);
          setChecked(true);
          if (response.result === true) {
            setRelayValidated(true);
          }
          else {
            setRelayValidatedError(response.message);
          }
        }).catch(function (error) {
          setRelayValidatedError("Failed to test relay. Try again.");
        });
    };
    return (
      <React.Fragment>
        {relayValidated ? (
          <Alert severity="success">Test Successful</Alert>
        ) : (<Button variant="contained" onClick={validaterelay} disabled={(loading || submitted || !casterValid || !portValid || !username || !password || !mountSelected)}>
          {!loading && !relayValidated && "Test"}
          {loading && (<CircularProgress size="1rem" color="inherit" />)}
          {!loading && checked && relayValidated && <CheckIcon color="success" />}
        </Button>
        )}
      </React.Fragment>
    );
  }

  const SubmitRelayButton = (props) => {
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);

    const submitrelay = () => {
      setLoading(true);
      setError(false);
      setMessage("");
      setSubmitted(false);
      let mountinfo = mountSelection.find(o => o.name === mountSelected);
      let relayParams = {
        server: caster,
        port: port,
        username: username,
        password: password,
        relay_mountpoint: mountSelected,
        name: alias,
        dip: dip,
        active: true,
        ...(!dip && {
          identifier: mountinfo && mountinfo.identifier ? mountinfo.identifier : "",
          format: mountinfo && mountinfo.format ? mountinfo.format : "",
          formatdetails: mountinfo && mountinfo.format_details ? mountinfo.format_details : "",
          carrier: mountinfo && mountinfo.carrier ? mountinfo.carrier : 0,
          navsystem: mountinfo && mountinfo.nav_system ? mountinfo.nav_system : "",
          network: mountinfo && mountinfo.network ? mountinfo.network : "",
          country: mountinfo && mountinfo.country ? mountinfo.country : "",
          latitude: mountinfo && mountinfo.latitude ? mountinfo.latitude : "",
          longitude: mountinfo && mountinfo.longitude ? mountinfo.longitude : "",
          nmea: mountinfo && mountinfo.nmea ? mountinfo.nmea : 1,
          solution: mountinfo && mountinfo.solution ? mountinfo.solution : 0,
          generator: mountinfo && mountinfo.generator ? mountinfo.generator : "",
          compression: mountinfo && mountinfo.compression ? mountinfo.compression : "",
          authentication: mountinfo && mountinfo.authentication ? mountinfo.authentication : "",
          fee: mountinfo && mountinfo.fee ? mountinfo.fee : "",
          bitrate: mountinfo && mountinfo.bitrate ? mountinfo.bitrate : 0,
          misc: mountinfo && mountinfo.misc ? mountinfo.misc : "",
        })
      }

      relayservice.createRelay(relayParams).then((response) => {
          setLoading(false);
          setError(false);
          setMessage("");
          setSubmitted(true);
          props.refresh();
        })
        .catch((error) => {
          setLoading(false);
          setError(true);
          setSubmitted(false);
          setMessage(error.message);
        });
    };
    return (
      <React.Fragment>
        {!submitted && (
          <Button variant="contained" onClick={submitrelay} disabled={(loading || submitted)}>
            {!submitted && "Save"}
            {loading && (<CircularProgress size="1rem" color="inherit" />)}
            {!loading && submitted && <CheckIcon color="success" />}
            {!loading && error && <ErrorOutlineIcon color="error" />}
          </Button>
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Stack spacing={2} direction="row" alignItems="center">
        <TextField
          id="caster-ip"
          label="Caster IP or Domain name"
          onChange={handleCasterChange}
          value={caster}
          error={!casterValid}
          helperText={!casterValid ? "Invalid caster address" : "Enter a valid dns name or IP."}
          autoFocus
        />
        <TextField
          id="caster-port"
          label="Caster Port"
          onChange={handlePortChange}
          value={port}
          error={!portValid}
          helperText={!portValid ? "Port number must be 1-63535." : "Enter a port number 1-63535."}
        />
      </Stack>
      <Stack spacing={2} direction="row" alignItems="center">
        {!dip && <GetMountpointsButton />}
        <ToggleButtonGroup
            value={dip}
            exclusive
            onChange={() => {
              setDip(!dip);
              setRelayValidated(!dip);
            }}
            aria-label="DIP"
        >
            <ToggleButton value={false} aria-label="NTRIP">NTRIP</ToggleButton>
            <ToggleButton value={true} aria-label="DIP">DIP</ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <Stack spacing={2} direction="column" alignItems="center">
        {downloadError && <Alert severity="error">{downloadError}</Alert>}
        
      {!dip && <Autocomplete
        freeSolo
        id="mountpoint-select"
        value={mountSelected}
        onInputChange={(event, newValue) => {
          handleChangeMountpoint(newValue);
        }}
        disableClearable
        options={mountSelection.map((mount) => mount.name)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search or Enter Mountpoint"
            InputProps={{
              ...params.InputProps,
              type: 'search'
            }}
            sx={{ width: 300 }}
          />
        )}
      />}
        
      </Stack>
      {mountSelected && (
        <React.Fragment>
          <Stack spacing={2} direction="row" alignItems="center">
            <TextField
              id="username"
              label="Username"
              onChange={handleUsernameChange}
              value={username}
              error={!usernameValid}
              helperText={!usernameValid ? "Check username." : "Enter a valid username."}
              disabled={!mountSelected}
            />

            <TextField
              id="password"
              label="Password"
              onChange={handlePasswordChange}
              value={password}
              error={!passwordValid}
              helperText={!passwordValid ? "Check password." : "Enter a valid password."}
              disabled={!mountSelected}
            />
          </Stack>
          <Stack spacing={2} direction="row" alignItems="center">
            {relayValidatedError && <Alert severity="error">{relayValidatedError}</Alert>}
            <ValidateRelayButton />
          </Stack>
        </React.Fragment>)
      }
      {relayValidated && (
        <Stack spacing={2} direction="column" alignItems="center">
          <TextField
            id="alias"
            label="Local Mountpoint Alias"
            onChange={handleAliasChange}
            value={alias}
            error={!aliasValid}
            helperText={!aliasValid ? "Invalid mountpoint name." : "Enter a valid mountpoint name."}
            disabled={!relayValidated}
          />
          <SubmitRelayButton refresh={() => props.refresh()} />
        </Stack>)
      }
    </React.Fragment >
  );
}



const validateCaster = (caster) => {
  if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(caster)) {
    return true;
  }
  else if (/([a-z0-9|-]+\.)*[a-z0-9|-]+\.[a-z]+/.test(caster)) {
    return true;
  }
  return false;
}

const validatePort = (port) => {
  if (/^[0-9][0-9]?[0-9]?[0-9]?[0-9]?$/.test(port)) {
    if (port > 0 && port <= 65535) {
      return true;
    }
  }
  return false;
}

const cleanPort = (port) => {
  return port.replace(/[^0-9.]/g, '');
}


const validateAlias = (alias) => {
  if (/[&\/\\#,+()$~%.'":*?<>{}]/.test(alias)) {
    return false;
  }
  else if (alias.length > 100) {
    return false;
  }
  else {
    return true;
  }
}

const cleanAlias = (alias) => {
  return alias.replace(" ", '_');
}
// return alias.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '_');