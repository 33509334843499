import axiosInstance from '../../../util/axiosAPI';
import { API_URL } from '../../../config';

export default class DataService {

    constructor() { }


    getStats() {
        const url = `${API_URL}/api/base/stats/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    files(period, filetype) {

        let url = `${API_URL}/api/data/files`;

        // Send request with query parameters if start year and start day exist.
        if(period.start && period.start.year && period.start.day) {
            url = `${API_URL}/api/data/files?filetype=${filetype || ""}&start_year=${period.start.year || ""}&start_day=${period.start.day || ""}&end_year=${period.end.year | ""}&end_day=${period.end.day || ""}`;
        }

        return axiosInstance.get(url).then(response => response.data);
    }

    getFile(file) {
        const url = `${API_URL}/api/files/${file}`;
        return axiosInstance.get(url, { responseType: 'blob' }).then(response => response.data);
    }

    deleteFile(file) {
        const url = `${API_URL}/api/files/${file}`;
        return axiosInstance.delete(url).then(response => response.data);
    }

    startRecording(duration) {
        const url = `${API_URL}/api/base/recording/`;
        return axiosInstance.post(url, { command: "start", duration: duration }).then(response => response.data);
    }

    stopRecording() {
        const url = `${API_URL}/api/base/recording/`;
        return axiosInstance.post(url, { command: "stop" }).then(response => response.data);
    }

    recordingStatus() {
        const url = `${API_URL}/api/base/recording/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    OPUSUpload(file, user_email) {
        const url = `${API_URL}/api/files/${file}`;
        return axiosInstance.post(url, { email: user_email }).then(response => response.data);
    }

    getStationId() {
        const url = `${API_URL}/api/base/stationid/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    setStationId(newStationId) {
        const url = `${API_URL}/api/base/stationid/`;
        const data = { "stationId": newStationId }
        return axiosInstance.post(url, data).then(response => response.data);
    }
}