import axiosInstance from '../../../util/axiosAPI';
import { API_URL } from '../../../config';

export default class NtripUserService {

    constructor() { }

    list() {
        const url = `${API_URL}/api/ntrip/users/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    getUsersByURL(link) {
        const url = `${API_URL}${link}`;
        return axiosInstance.get(url).then(response => response.data);
    }

    get(id) {
        const url = `${API_URL}/api/ntrip/users/${id}/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    delete(id) {
        const url = `${API_URL}/api/ntrip/users/${id}/`;
        return axiosInstance.delete(url);
    }

    create(user) {
        const url = `${API_URL}/api/ntrip/users/`;
        return axiosInstance.post(url, user);
    }

    // update(user) {
    //     const url = `${API_URL}/api/users/${user.pk}`;
    //     return axiosInstance.put(url, user);
    // }

    update(user) {
        const url = `${API_URL}/api/ntrip/users/${user.id}/`;
        return axiosInstance.patch(url, user);
    }
}
