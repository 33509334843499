import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import ReceiverService from '../receiverService';
import SourceService from '../../sources/sourceService';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import { LinearProgress } from '@mui/material';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import RTKServiceApi from './service';
import RTKServiceForm from './form';

const sourceservice = new SourceService();
const rtkservice = new RTKServiceApi();

export default function RTKServiceEdit() {
  let navigate = useNavigate();

  let { id } = useParams();

  return (
    <RTKServiceForm id={id} />
  );
}