import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { CircularProgress, LinearProgress } from '@mui/material';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import { WEBSOCKET_URL } from "../../../config";
import RinexServiceApi from './service';
import { SettingsEthernet } from '@mui/icons-material';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import CircleIcon from '@mui/icons-material/Circle';
import BytesDisplay from '../../../components/BytesDisplay';

function fancyTimeFormat(duration) {
  // Hours, minutes and seconds
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;

  return ret;
}

const rinexservice = new RinexServiceApi();

export default function RINEXServiceStatusWidget(props) {
  let navigate = useNavigate();
  const id = props.id;
  const StatusWebSocket = useRef(null);

  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState();
  const [status, setStatus] = useState({});

  const [oldBytes, setOldBytes] = useState(0);
  const [progress, setProgress] = useState(0);
  const [increment, setIncrement] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);
     rinexservice.get(id).then(data => {
      setSettings(data);
      setLoading(false);
    });
  }

  useEffect(() => {
    if(progress > 100 ) setProgress(progress - 100);
  }, [progress]);
  
  useEffect(() => {
    // Calculate new progress bar value
    if(status.hasOwnProperty('bytes') && status.bytes) {

      if(oldBytes === 0) {
        setIncrement(0);
      }
      else {
        setIncrement((Number(status.bytes) - Number(oldBytes)) / 100);
      }

      setProgress(progress + increment);
      setOldBytes(status.bytes);

    }
  
  }, [status.bytes]);

  useEffect(() => {
    StatusWebSocket.current = new WebSocket(
      WEBSOCKET_URL
      + '/ws/'
      + 'gnss'
      + '/'
      + id + '/');

      StatusWebSocket.onclose = function (e) {
      console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
      setTimeout(function () {
        StatusWebSocket.current = new WebSocket(
          WEBSOCKET_URL
          + '/ws/'
          + 'gnss'
          + '/'
          + id + '/');
      }, 1000);
    };

    StatusWebSocket.current.onmessage = function (e) {
      try {
        const data = JSON.parse(e.data);

        // Update status object with new data
        setStatus(data);

      }
      catch { }

    };

    return () => StatusWebSocket.current.close();

  }, []);

  if (loading) {
    return <LinearProgress />
  }
  else {
    return (
      <Paper style={{ marginTop: "2em", marginBottom: "2em", padding: "1em", width: "100%" }} >
          <Grid
            justifyContent="space-between"
            container
            direction="row"
            spacing={5}
          >
            <Grid item>
              <h3>{status.running ? <CircleIcon color='success' /> : <CircleIcon color='error' />} <b>RINEX</b> <SettingsEthernet /> {settings.path}</h3>
            </Grid>
          </Grid>
          <br />
          <Grid
            justifyContent="space-between"
            container
            direction="row"
            spacing={2}
          >
            <Grid item>
            <h5>Logfile</h5>
              <Typography>Filename: {status.filename}</Typography>
              <Typography>Bytes: <BytesDisplay bytes={status.bytes} /></Typography>
              {settings.radio_enabled ? <Typography>To Radio: <BytesDisplay bytes={status.radio_bytes_sent} /></Typography> : null}
              <Typography color="green">+<BytesDisplay bytes={increment} /></Typography>
            </Grid>
            <Grid item>
            <h5>Run Time</h5>
              {status.running ? (
                <Typography style={{color: "green"}}>{fancyTimeFormat(status.run_time_secs)}</Typography>
              ):(
                <Typography style={{color: "red"}}>{fancyTimeFormat(status.run_time_secs)}</Typography>
              )}
            </Grid>
            <Grid item>
              <h5>RINEX Header</h5>
              <Typography>Agency: {status.header_agency}</Typography>
              <Typography>Marker Name: {status.header_marker_name}</Typography>
            </Grid>
            <Grid item>
              <h5>Collection Settings</h5>
              <Typography>Interval: {status.interval} seconds</Typography>
              <Typography>Constellations: {status.const_gps && <span style={{color: "blue"}}>GPS</span>} {status.const_glonass && <span style={{color: "green"}}>GLONASS</span>} {status.const_galileo && <span style={{color: "red"}}>GALILEO</span>} {status.const_beidou && <span style={{color: "gold"}}>BeiDou</span>}</Typography>
            </Grid>
            <Grid item>
              <h5>Device Connection</h5>
              <Typography>Serial Port: {status.hasOwnProperty('gnss') ? status.gnss.path : "-"}</Typography>
              <Typography>Baudrate: {status.hasOwnProperty('gnss') ? status.gnss.baudrate : "-"}</Typography>
            </Grid>
            <Grid item>
            <h5>GNSS Reciever Info</h5>
              <Typography>Serial Number: {status.hasOwnProperty('gnss') ? status.gnss.serialnumber : "-"}</Typography>
              <Typography>Firmware: {status.hasOwnProperty('gnss') ? status.gnss.firmwareversion : "-"}</Typography>
              <Typography>Hardware: {status.hasOwnProperty('gnss') ? status.gnss.hardwareversion : "-"}</Typography>
              <Typography>Activations: {status.hasOwnProperty('gnss') ? status.gnss.options : "-"}</Typography>
            </Grid>
          </Grid>
          <br />

        {status.running ? (
          <LinearProgress 
            variant="determinate" 
            value={progress}
            color="success"
            sx={{
              height: 6
            }}
        />
        ) : (
          <LinearProgress 
          variant="determinate" 
          value={1}
          color="error"
          sx={{
            height: 6
          }}
      />
        )}
        <br />
        <Typography color="gray">Status: {status.message}</Typography>
        <br />

        {loading ? <LinearProgress /> :(
          <React.Fragment>
            <IconButton aria-label="Start" disabled={status.running} onClick={() => {rinexservice.start(settings.id); setOldBytes(0);}}>
              <PlayCircleFilledIcon color="success" />
            </IconButton>
            <IconButton aria-label="Stop" disabled={status.running === false} onClick={() => {rinexservice.stop(settings.id); setOldBytes(0);}}>
              <StopCircleIcon color="error" />
            </IconButton>
            <IconButton aria-label="Restart" onClick={()=>rinexservice.sync(settings.id)}>
              <ReplayCircleFilledIcon color="" />
            </IconButton>

            
            <Button style={{float: "right" }} variant="contained" onClick={()=>navigate("/gnss/rinex/" + id + "/edit")}><SettingsIcon /> Settings</ Button>
          </React.Fragment>
        )}
        <Typography style={{color: "grey"}}>Service ID: {id}</Typography>
      </Paper>
    );
  }
}