import axiosInstance from '../../util/axiosAPI';
import { API_URL } from '../../config';

export default class ActivityService {

    constructor() { }

    list() {
        const url = `${API_URL}/api/ntrip/logins/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    getUrl(query) {
        const url = `${query}`;
        return axiosInstance.get(url).then(response => response.data);
    }

    listUserActivity() {
        const url = `${API_URL}/api/ntrip/logins/?type=User`;
        return axiosInstance.get(url).then(response => response.data);
    }

    listSourceActivity() {
        const url = `${API_URL}/api/ntrip/logins/?type=Source`;
        return axiosInstance.get(url).then(response => response.data);
    }

    clear() {
        const url = `${API_URL}/api/ntrip/logins/clear/`;
        return axiosInstance.get(url).then(response => response.data);
    }
}
