import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { toast } from "react-toastify";
import DataService from '../dataService';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const dataservice = new DataService();

class StationId extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentStationId: '',
            newStationId: '',
            validationMsg: 'Enter a 4 character station ID.',
            Warning: false,
            inputValid: false,
            modal: false,
            loading: false,

        }

        this.validateInput = this.validateInput.bind(this);
        this.setStationId = this.setStationId.bind(this);
        this.getStationId = this.getStationId.bind(this);
        this.modalToggle = this.modalToggle.bind(this);

    }

    componentDidMount() {
        this.getStationId();
    }

    modalToggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    getStationId() {
        // this.state.loading("Getting station ID...");
        dataservice.getStationId()
            .then((data) => {
                this.setState({
                    currentStationId: data.stationid,
                });
                // this.state.loading();
            });
        return true;
    }

    setStationId() {
        this.modalToggle();
        // this.state.loading("Setting station ID to " + this.state.newStationId + "...");
        dataservice.setStationId(this.state.newStationId).then((data) => {
            this.setState({
                currentStationId: data.stationid,
                newStationId: '',
                validationMsg: 'Enter a 4 character station ID.',
                Warning: false,
                inputValid: false
            });
            // this.state.loading();
            toast.success("Station ID updated to " + data.stationid);
        });
        return true;
    }

    validateInput(e) {
        if (e.target.value.length > 4) {
            this.setState({ validationMsg: "Too many characters. Enter a 4 character station ID." });
            this.setState({ newStationId: e.target.value.toUpperCase() });
            this.setState({ Warning: 'yellow' })
            this.setState({ inputValid: false })
            return false
        }
        else if (e.target.value.length < 4) {
            this.setState({ validationMsg: "Not enough characters. Enter a 4 character station ID." });
            this.setState({ newStationId: e.target.value.toUpperCase() });
            this.setState({ Warning: 'yellow' })
            this.setState({ inputValid: false })
            return false
        }
        else if (!e.target.value) {
            this.setState({ validationMsg: "Enter a 4 character station ID." });
            this.setState({ newStationId: e.target.value.toUpperCase() });
            this.setState({ Warning: 'white' })
            this.setState({ inputValid: false })
            return false
        }
        else {
            this.setState({ newStationId: e.target.value.toUpperCase() });
            this.setState({ validationMsg: "Station ID OK." });
            this.setState({ Warning: 'green' })
            this.setState({ inputValid: true })
            return true
        }
    }

    render() {

        return (
            <div>
                <form>
                    <h2>Station ID: {this.state.currentStationId}</h2>
                    <br />
                    <h5>Change station ID</h5>
                    <div className="form-group row">
                        <div className="col-sm-10">
                            <span style={{ color: this.state.Warning }}>{this.state.validationMsg}</span><br />
                            <input className="form-control col-sm-4" style={{ marginTop: "5px", marginBottom: "5px" }} type="text" id="stationid" ref='stationid' value={this.state.newStationId} onChange={this.validateInput} />
                            <input className="btn btn-primary" type="submit" value="Update Station ID" onClick={this.modalToggle} disabled={!this.state.inputValid} />
                        </div>
                    </div>
                </form>

                <Modal isOpen={this.state.modal} toggle={this.modalToggle} className={this.props.className}>
                    <ModalHeader toggle={this.modalToggle}>Confirm new station name</ModalHeader>
                    <ModalBody>
                        Are you sure you want to change <strong>{this.state.currentStationId}</strong> to <strong>{this.state.newStationId}</strong>?
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' onClick={this.setStationId}>Yes</Button>{' '}
                        <Button color='danger' onClick={this.modalToggle}>No</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default StationId;
