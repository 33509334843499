import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PeopleIcon from '@mui/icons-material/People';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import PersonIcon from '@mui/icons-material/Person';
import Badge from '@mui/material/Badge';
import { LinearProgress } from '@mui/material';
import BytesDisplay from '../../../components/BytesDisplay';

export default function ActiveUsersTable(props) {

  const [age, setAge] = useState(10);
  const [current, setCurrent] = useState(false);
  const prevAge = useRef(10);

  useEffect(() => {
    prevAge.current = age;
  }, [age]);

  useEffect(() => {
    setAge(new Date());
  }, [props]);

  useEffect(() => {
    const timer = setInterval(() => {
      let currentDate = new Date();
      let diff = currentDate - prevAge.current;
      let diffSeconds = diff / (1000);
      if (diffSeconds <= 5) setCurrent(true);
      else setCurrent(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const FixTypes = {
    4: "RTK Fixed",
    5: "RTK Float",
    2: "DGPS",
    1: "Autonomous",
    0: "No position"
  }

  const UserRowItem = ({ user }) =>
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {user.ID}
      </TableCell>
      <TableCell scope="row">
        <Chip label={user.USER} icon={<PersonIcon />} />
      </TableCell>
      <TableCell scope="row">
        {user.nearest ? (
          <Chip label={user.MOUNT} color="warning" variant="outlined" />
        ) : (
          <Chip label={user.MOUNT} color="default" variant="outlined" />
        )
        }
      </TableCell>
      <TableCell align="left">{FixTypes[user.fixtype]}</TableCell>
      <TableCell align="left">{user.sats}</TableCell>
      <TableCell align="left">{user.USERAGENT}</TableCell>
      <TableCell align="left">{user.HOST}</TableCell>
      <TableCell align="left">{user.TIME}</TableCell>
      <TableCell align="left"><BytesDisplay bytes={user.BYTES} /></TableCell>
    </TableRow>
    ;

  return (
    <React.Fragment>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">ID</TableCell>
            <TableCell align="left">Username</TableCell>
            <TableCell align="left">Mountpoint</TableCell>
            <TableCell align="left">Fix Type</TableCell>
            <TableCell align="left">Sats Used</TableCell>
            <TableCell align="left">NTRIP Client</TableCell>
            <TableCell align="left">IP</TableCell>
            <TableCell align="left">Duration</TableCell>
            <TableCell align="left">Stream</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.activeUsers.map((user) => (<UserRowItem key={user.ID} user={user} />))}
        </TableBody>
      </Table>
      {!current && <LinearProgress speed={100} />}
      {props.activeUsers.length === 0 && "No active users"}
    </React.Fragment>
  );
}

