import * as React from 'react';
import { useParams } from "react-router-dom";
import RINEXServiceStatusWidget from './statusWidget';

export default function RINEXServiceStatus() {
  let { id } = useParams();

    return (
      <RINEXServiceStatusWidget id={id} />
    );
}