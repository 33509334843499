import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import { useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import RTKServiceApi from './service';
import moment from 'moment';
import RTKServiceStatusWidget from './statusWidget';
import Button from '@mui/material/Button';
const rtkservice = new RTKServiceApi();

export default function RTKServiceList() {
  let navigate = useNavigate();
  
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState([]);


  useEffect(() => {
    setLoading(true);
    rtkservice.list().then((response) => {
      setServices(response);
      setLoading(false);
    });
  }, []);

    return (
      <React.Fragment>
        <h1 className="page-title"><span className="fw-semi-bold">RTK Streams</span></h1>
        <Stack direction="row">
                {services.map((service) => {
                    return (
                        // <Stack direction="row" >
                        //     <Paper style={{ marginTop: "2em", marginBottom: "2em", padding: "1em", minWidth: "15vw", maxWidth: "50vw" }} >
                        //         <h3>RTK Stream Config</h3>
                        //         <p>ID: {service.id}</p>
                        //         <p><b>Serial Settings</b></p>
                        //         <p>Device Port: {service.path}</p>
                        //         <p>Device Baudrate: {service.baudrate}</p>
                        //         <p><b>Caster Settings</b></p>
                        //         <p>Mountpoint: {service.mountpoint}</p>
                        //         <p>Caster IP: {service.casterip}</p>
                        //         <p>Caster Port: {service.casterport}</p>
                        //         <p>Username: {service.user}</p>
                        //         <p>Password: {service.password}</p>
                        //         <p><b>GNSS Settings</b></p>
                        //         <p>Protocol: {service.protocol}</p>
                        //         <p>Broadcast ID: {service.mountpoint}</p>
                        //         <p>Constellations: {service.gps && <span style={{color: "blue"}}>GPS</span>} {service.glonass && <span style={{color: "green"}}>GLONASS</span>} {service.galileo && <span style={{color: "red"}}>GALILEO</span>} {service.beidou && <span style={{color: "yellow"}}>BeiDou</span>}</p>
                        //         <p><b>Coordinate</b></p>
                        //         <p>Latitude: {service.coordinate.latitude}</p>
                        //         <p>Longitude: {service.coordinate.longitude}</p>
                        //         <p>Altitude: {service.coordinate.altitude} m</p>
                        //         <p>Set on {moment(service.coordinate.datetime, moment.ISO_8601).format("MM/DD/YYYY HH:mm:ss")} by {service.coordinate.user}</p>
                        //         <p>Datum: {service.coordinate.datum ? service.coordinate.datum : "None"}</p>
                        //         <p>Note: {service.coordinate.note ? service.coordinate.note : "None"}</p>
                        //         <p><b>Radio</b></p>
                        //         <p>{service.radio_enabled ? <span style={{color: "green"}}>Enabled</span> : <span style={{color: "red"}}>Disabled</span>}</p>
                        //         {service.radio_enabled && 
                        //             <React.Fragment>
                        //                 <p>Device Path: {service.radio_device}</p>
                        //                 <p>Baudrate: {service.radio_baudrate} m</p>
                        //                 <p>Frequency: {service.radio_frequency}</p>
                        //                 <p>Protocol: {service.radio_protocol}</p>
                        //                 <p>Transmit Power: {service.radio_tx_power}</p>
                        //                 <p>Channel Spacing: {service.radio_channel_spacing}</p>
                        //             </React.Fragment>
                        //         }
                        //     </Paper>
                        //     <Paper style={{ marginTop: "2em", marginBottom: "2em", padding: "1em", minWidth: "15vw", maxWidth: "50vw" }} >
                        //         <h3>Status</h3>

                        //         <p>Mountpoint: {service.mountpoint}</p>
                        //         <p>Device Port: {service.path}</p>
                        //         <p>Device Baudrate: {service.baudrate}</p>
                        //         <p>Protocol: {service.protocol}</p>
                        //         <p>Caster IP: {service.casterip}</p>
                        //         <p>Caster Port: {service.casterport}</p>
                        //         <p>Username: {service.user}</p>
                        //         <p>Password: {service.password}</p>
                        //         <p>Broadcast ID: {service.mountpoint}</p>
                        //         <p>Constellations: {service.gps && <span style={{color: "blue"}}>GPS</span>} {service.glonass && <span style={{color: "green"}}>GLONASS</span>} {service.galileo && <span style={{color: "red"}}>GALILEO</span>} {service.beidou && <span style={{color: "yellow"}}>BeiDou</span>}</p>


                        //     </Paper>
                        //   </Stack>
                          <RTKServiceStatusWidget id={service.id} />
                          );
                })}
        </Stack>
        <Button variant="contained" onClick={()=>navigate("/gnss/rtk/new")}>+ New RTK Stream</ Button>
      </React.Fragment>
    );
}
