import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import RINEXServiceForm from './form';

export default function RINEXServiceEdit() {
  let { id } = useParams();

  return (
    <RINEXServiceForm id={id} />
  );
}