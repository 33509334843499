import * as React from 'react';
import { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import qs from 'qs';

import axios from "axios";
import { API_URL } from "../../config";
import { login } from '../../redux/store/auth.slice';
import { useDispatch } from 'react-redux';
import { Alert, CircularProgress } from '@mui/material';
import axiosInstance from '../../util/axiosAPI';

import logo from './FastRTK-logo.png'


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://fastrtk.com/">
                FastRTK
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export default function Login() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [warning, setWarning] = useState("");

    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        setLoading(true);
        setError("");
        setWarning("");
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const username = data.get('username')
        const password = data.get('password')

        const axiosdata = { 
            'grant_type': 'password',
            'username': username,
            'password': password,
            'scope': 'openid'
         };

        const basicauth = btoa('PSgWb4jCwrdUFZVMaKABgRLvLj0JmPRbpWNlsick' + ':' + '9oWTUW8w0XE351TeRqIRVPU9zjZp0F1I0XRyNFdFWcik6EsApnfD0RLOMuHDHAAAiefVJrlFtKKkciHGZiJbnJmx2cgTP0JmiEtAITnLIndcmutQ8N6oPJjqqnyc06JQ');
        
        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'Authorization': `Basic ${basicauth}` },
          data: qs.stringify(axiosdata),
          url: API_URL + '/api/o/token/',
          timeout: 10000
        };

        axios(options)
            .then(response => {
                const session = response.data;
                localStorage.setItem("session", JSON.stringify(session));
                localStorage.setItem("username", username);
                dispatch(login());
            })
            .catch(error => {
                if (error.code === "ECONNABORTED") {
                    setWarning("Connection timeout. Try again.");
                }
                else if (error.code === "ERR_BAD_REQUEST") {
                    setError(error.response.data.error_description);
                }
                else {
                    setError(error.message);
                }
                setLoading(false);
            })           
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar> */}
                    <img src={logo} alt="Logo" style={{width: "20em" }} />;
                    {/* <Typography component="h1" variant="h5">
                        Sign in
                    </Typography> */}
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        {error && <Alert severity='error'>{error}</Alert>}
                        {warning && <Alert severity='warning'>{warning}</Alert>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {!loading && "Sign In"}
                            {loading && <CircularProgress size="1rem" color="inherit" />}
                        </Button>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}