import React, { Component } from 'react';
import { Progress } from 'reactstrap';
import DataService from '../dataService';
import s from './data.module.scss';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import RecordingProgress from './RecordingProgress';


const dataService = new DataService();

class Recording extends Component {

    constructor(props) {
        super(props);
        this.state = {
            recordingActive: null,
            recordingDays: '',
            recordingHours: '',
            recordingMinutes: '',
            recordingDuration: null,
            recordingElapsed: null,
            recordingProgress: null,
            recordingBytes: null,
            recordingFilename: '',
            modal: false,
            units: '',
        }

        this.modalToggle = this.modalToggle.bind(this);
        this.handleStartRecording = this.handleStartRecording.bind(this);
        this.handleStopRecording = this.handleStopRecording.bind(this);
        this.changeRecordingDays = this.changeRecordingDays.bind(this);
        this.changeRecordingHours = this.changeRecordingHours.bind(this);
        this.changeRecordingMinutes = this.changeRecordingMinutes.bind(this);
        this.getRecordingStatus = this.getRecordingStatus.bind(this);
        this.handleUnitSelection = this.handleUnitSelection.bind(this);
    }

    componentDidMount() {
        this.timer = setInterval(() => this.getRecordingStatus(), 1000);
    }


    componentWillUnmount() {
        clearInterval(this.timer);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
    }

    changeRecordingDays(event) {
        this.setState({ recordingDays: event.target.value });
    }

    changeRecordingHours(event) {
        this.setState({ recordingHours: event.target.value });
    }

    changeRecordingMinutes(event) {
        this.setState({ recordingMinutes: event.target.value });
    }


    getRecordingStatus() {
        dataService.recordingStatus().then(data => {
            this.setState({
                recordingActive: data.payload.recording,
                recordingDuration: data.payload.duration,
                recordingElapsed: data.payload.elapsed,
                recordingProgress: data.payload.progress,
                recordingBytes: data.payload.bytes,
                recordingFilename: data.payload.filename
            })
        });
    }

    modalToggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    handleStartRecording() {
        this.setState({ recordingActive: true });
        const data = { duration: ((this.state.recordingDays * 24 * 60) + (this.state.recordingHours * 60) + (this.state.recordingMinutes * 1)) };
        dataService.startRecording(data.duration);
    }

    handleStopRecording(event) {
        event.preventDefault();
        this.setState({ recordingActive: false });
        dataService.stopRecording().then(() => {
            var newArray = this.state.storageFiles.filter((obj) => {
                return obj;
            });
            this.setState({ storageFiles: newArray });
        });

    }

    handleUnitSelection(event) {
        this.setState({ units: event.target.value });
    };


    render() {


        function ObservationLength(props) {

            var days = Math.floor((props.minutes) / (60 * 24));
            var hours = Math.floor(((props.minutes) - (days * 24 * 60)) / 60);
            var minutes = Math.floor((props.minutes) - ((days * 24 * 60) + (hours * 60)));

            if (!props.minutes) {
                return null;
            }
            else {
                return (
                    <span>Days: {days} Hours: {hours} Minutes: {minutes}</span>
                );
            }
        }

        if (!this.state.recordingActive) {
            return (
                <React.Fragment>
                    <RecordingProgress />
                    <form onSubmit={this.handleStartRecording}>
                        <TextField
                            id="outlined-number"
                            label="Number"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <FormControl style={{ width: "10em" }}>
                            <InputLabel id="demo-simple-select-label">Units</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.timeUnits}
                                label="Custom Duration"
                                onChange={this.handleUnitSelection}
                            >
                                <MenuItem value="D">Days</MenuItem>
                                <MenuItem value="H">Hours</MenuItem>
                                <MenuItem value="M">Minutes</MenuItem>
                            </Select>
                        </FormControl>
                        <br />
                        <input className="btn btn-primary" type="submit" value={"Record"} />
                        <input className="btn btn-primary" type="submit" value="48 hours" onClick={() => { this.setState({ recordingHours: 48 }, function () { this.handleStartRecording() }) }} />
                        <input className="btn btn-primary" type="submit" value="24 hours" onClick={() => { this.setState({ recordingHours: 24 }, function () { this.handleStartRecording() }) }} />
                        <input className="btn btn-primary" type="submit" value="12 hours" onClick={() => { this.setState({ recordingHours: 12 }, function () { this.handleStartRecording() }) }} />
                        <input className="btn btn-primary" type="submit" value="2 hours" onClick={() => { this.setState({ recordingHours: 2 }, function () { this.handleStartRecording() }) }} />
                        <input className="btn btn-primary" type="submit" value="1 hour" onClick={() => { this.setState({ recordingHours: 1 }, function () { this.handleStartRecording() }) }} />
                        <input className="btn btn-primary" type="submit" value="30 minutes" onClick={() => { this.setState({ recordingMinutes: 30 }, function () { this.handleStartRecording() }) }} />
                        <input className="btn btn-primary" type="submit" value="15 minutes" onClick={() => { this.setState({ recordingMinutes: 15 }, function () { this.handleStartRecording() }) }} />
                    </form>
                </React.Fragment>
            );
        }

        else {
            return (
                <div className="recording-progress">
                    <h3>Observation in progress</h3>
                    <div className={[s.storage, 'ml-2 text-center',].join(' ')}>
                        <Progress
                            color="success"
                            value={this.state.recordingProgress}
                            className="progress-sm mb-xs"
                            animated
                        />
                        <p>{this.state.recordingProgress}% complete</p>
                    </div>
                    <table>
                        <tbody>
                            <tr>
                                <td>Duration:</td>
                                <td><ObservationLength minutes={this.state.recordingDuration} /></td>
                            </tr>
                            <tr>
                                <td>Remaining:</td>
                                <td><ObservationLength minutes={(this.state.recordingDuration - this.state.recordingElapsed)} /></td>
                            </tr>
                            <tr>
                                <td>File name:</td>
                                <td>{this.state.recordingFilename}</td>
                            </tr>
                            <tr>
                                <td>File size:</td>
                                <td>{(this.state.recordingBytes) / 1000000} MB</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <p className="width-250">Data collection will continue after this interface is closed. Powering off the base will stop data collection.</p>
                    <form onSubmit={this.handleStopRecording}>
                        <input className="btn btn-danger" type="submit" value="Stop Recording" />
                    </form>
                </div>
            );
        }
    }

}

export default Recording;

