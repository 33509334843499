import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import MountpointService from './service';

const mountservice = new MountpointService;


export default function MountpointEdit() {

  let { id } = useParams(); // Mountpoint ID

  const [mountpoint, setMountpoint] = useState({})
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);


  const [name, setName] = useState(""); // Source identifier (most time nearest city)
  const [nameValid, setNameValid] = useState(true);

  const handleNameChange = (e) => {
    let value = e.target.value;
    setName(cleanName(value));
    setNameValid(validateName(value));
  };

  const cleanName = (value) => {
    return value.replace(" ", '_');
  }

  const validateName = (value) => {
    if (/[&\/\\#,+()$~%.'":*?<>{}]/.test(value)) {
      return false;
    }
    else if (value.length > 100) {
      return false;
    }
    else {
      return true;
    }
  }

  const [identifier, setIdentifier] = useState(""); // Source identifier (most time nearest city)
  const [identifierValid, setIdentifierValid] = useState(true);

  const handleIdentifierChange = (e) => {
    let value = e.target.value;
    setIdentifier(value);
    setIdentifierValid(ValidateIdentifier(value));
  };

  function ValidateIdentifier(input) {
    if (input.length > 100) return false;
    return true;
  }

  const [misc, setMisc] = useState(""); // Text
  const [miscValid, setMiscValid] = useState(true);

  const handleMiscChange = (e) => {
    let value = e.target.value;
    setMisc(value);
    setMiscValid(ValidateMisc(value));
  };

  function ValidateMisc(input) {
    if (input.length > 100) return false;
    return true;
  }

  const [format, setFormat] = useState(""); // Format

  function handleFormatChange(e) {
    setFormat(e.target.value);
  }

  const [formatdetails, setFormatDetails] = useState(""); // Individual messages and their output rate msg(freq)
  const [formatDetailsValid, setFormatDetailsValid] = useState(true);

  const [carrier, setCarrier] = useState(""); // 0=No phase information 1=L1 2=L2 5=L5

  function handleCarrierChange(e) {
    setCarrier(e.target.value);
  }

  const [navsystem, setNavsystem] = useState("fdsfsfds"); // GPS GLO GAL BDS
  const [gps, setGPS] = useState(false);
  const [glonass, setGlonass] = useState(false);
  const [galileo, setGalileo] = useState(false);
  const [beidou, setBeidou] = useState(false);

  function handleNavsystemChange() {
    let tmpNavsystem = [];
    if (gps) tmpNavsystem.push("GPS");
    if (glonass) tmpNavsystem.push("GLO");
    if (galileo) tmpNavsystem.push("GAL");
    if (beidou) tmpNavsystem.push("BDS");

    setNavsystem(tmpNavsystem.join("+"));
  }

  useEffect(() => {
    handleNavsystemChange();
  }, [gps, glonass, galileo, beidou]);


  const [network, setNetwork] = useState(""); // Text. Network name
  const [networkValid, setNetworkValid] = useState(true);

  const [country, setCountry] = useState(""); // 3-digit country code
  const [countryValid, setCountryValid] = useState(true); // 3-digit country code

  function handleCountryChange(e) {
    let value = e.target.value.toUpperCase();
    setCountry(value);
    setCountryValid(validateCountry(value));
  }

  function validateCountry(input) {
    if (/^[A-Z][A-Z][A-Z]$/.test(input)) {
      return true;
    }
    return false;
  }

  const [latitude, setLatitude] = useState(""); // Decimal
  const [latitudeValid, setLatitudeValid] = useState(true);

  const handleLatitudeChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9.-]+/g, '');
    setLatitude(value);
    setLatitudeValid(ValidateLatitude(value));
  };

  function ValidateLatitude(input) {
    if (/^[0-9][0-9].[0-9][0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?$/.test(input)) {
      return true;
    }
    return false;
  }

  const [longitude, setLongitude] = useState(""); // Decimal
  const [longitudeValid, setLongitudeValid] = useState(true);

  const handleLongitudeChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9.-]+/g, '');
    setLongitude(value);
    setLongitudeValid(ValidateLongitude(value));
  };

  function ValidateLongitude(input) {
    if (/^[-]?[0-9][0-9][0-9]?.[0-9][0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?$/.test(input)) {
      return true;
    }
    return false;
  }

  const [nmea, setNmea] = useState(0); // 1 or 0
  const [nmeaBool, setNmeaBool] = useState(false);

  useEffect(() => {
    if (nmeaBool) setNmea(1);
    else setNmea(0);
  }, [nmeaBool]);


  const [solution, setSolution] = useState("0"); // 1 or 0

  function handleSolutionChange(e) {
    let value = e.target.value;
    setSolution(value);
  }

  const [generator, setGenerator] = useState("Eos Arrow Gold"); // Text. Name of correction generator
  const [generatorValid, setGeneratorValid] = useState(true);

  const handleGeneratorChange = (e) => {
    let value = e.target.value;
    setGenerator(value);
    setGeneratorValid(ValidateGenerator(value));
  };

  function ValidateGenerator(input) {
    if (input.length > 100) return false;
    return true;
  }

  const [compression, setCompression] = useState("none"); // Text. Compression algorithm
  const [compressionValid, setCompressionValid] = useState(true);

  const handleCompressionChange = (e) => {
    let value = e.target.value;
    setCompression(value);
    setCompressionValid(ValidateCompression(value));
  };

  function ValidateCompression(input) {
    if (input.length > 100) return false;
    return true;
  }

  const [authentication, setAuthentication] = useState("B"); // B=basic D=digest N=none
  const [authenticationValid, setAuthenticationValid] = useState(true);

  function handleAuthenticationChange(e) {
    let value = e.target.value;
    setAuthentication(value);
  }

  const [fee, setFee] = useState(true); // Y or N
  const [feeBool, setFeeBool] = useState(true);

  useEffect(() => {
    if (feeBool) setFee("Y");
    else setFee("N");
  }, [feeBool]);

  const [bitrate, setBitrate] = useState(0); // Integer
  const [bitrateValid, setBitrateValid] = useState(true);

  const handleBitrateChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9]+/g, '');
    setBitrate(value);
    setBitrateValid(ValidateBitrate(value));
  };

  function ValidateBitrate(input) {
    if (/^[0-9]+$/.test(input)) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    setLoading(true);
    mountservice.get(id).then(response => {
      setMountpoint(response);
      setLoading(false);
      setName(response.name);
      setIdentifier(response.identifier);
      setMisc(response.misc);
      setFormat(response.format);
      if (response.format === "RTCM3") setFormat("RTCM 3.2");
      setFormatDetails(response.formatdetails);
      setCarrier(response.carrier);
      if (response.navsystem.includes("GPS") || response.navsystem.includes("gps")) setGPS(true);
      if (response.navsystem.includes("GLO") || response.navsystem.includes("glonass") || response.navsystem.includes("glo")) setGlonass(true);
      if (response.navsystem.includes("GAL") || response.navsystem.includes("galileo") || response.navsystem.includes("gal")) setGalileo(true);
      if (response.navsystem.includes("BDS") || response.navsystem.includes("beidou") || response.navsystem.includes("bds") || response.navsystem.includes("BEIDOU")) setBeidou(true);
      setNetwork(response.network);
      setCountry(response.country);
      setLatitude(response.latitude);
      setLongitude(response.longitude);
      setNmea(response.nmea);
      if (response.nmea === 1) setNmeaBool(true);
      else setNmeaBool(false);
      setSolution(response.solution);
      setGenerator(response.generator);
      setCompression(response.compression);
      setAuthentication(response.authentication);
      setFee(response.fee);
      setBitrate(response.bitrate);
    });


  }, []);

  function formValid() {
    return (
      identifierValid &&
      miscValid &&
      formatDetailsValid &&
      networkValid &&
      countryValid &&
      latitudeValid &&
      longitudeValid &&
      generatorValid &&
      compressionValid &&
      authenticationValid &&
      bitrateValid);
  }

  function submit() {
    setSubmitting(true);
    let updatedMount = {
      id: mountpoint.id,
      name: name,
      authentication: authentication,
      bitrate: bitrate,
      carrier: carrier,
      compression: compression,
      country: country,
      fee: fee,
      format: format,
      formatdetails: formatdetails,
      generator: generator,
      identifier: identifier,
      latitude: latitude,
      longitude: longitude,
      misc: misc,
      navsystem: navsystem,
      network: network,
      nmea: nmea,
      solution: solution,
    };
    mountservice.update(updatedMount)
      .then((response) => {
        setSubmitting(false);
        setSuccess(true);
        setMessage(mountpoint.name + " updated.");
      })
      .catch((error) => {
        console.log(error);
        setError(true);
        setMessage("Failed to update " + mountpoint.name + ". " + error.response.data);
      });
  }

  if (loading) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }

  else {

    return (
      <React.Fragment>
        <h1>Mountpoint Detail: {mountpoint.name}</h1>
        <Stack spacing={2} direction="column" alignItems="center" style={{ maxWidth: "20em" }}>
          <TextField
            fullWidth
            id="name"
            label="Mountpoint"
            onChange={handleNameChange}
            value={name}
          />
          <TextField
            fullWidth
            id="identifier"
            label="Identifier"
            onChange={handleIdentifierChange}
            value={identifier}
            error={!identifierValid}
            helperText={!identifierValid ? "Invalid identifier." : "Enter a valid identifier description."}
          />
          <TextField
            fullWidth
            id="misc"
            label="Additional Info"
            onChange={handleMiscChange}
            value={misc}
            error={!miscValid}
            helperText={!miscValid ? "Too long." : "Enter a note(100 chars max)."}
          />
          <FormControl fullWidth>
            <InputLabel id="format">Format</InputLabel>
            <Select
              labelId="format"
              id="format-select"
              value={format}
              label="Format"
              onChange={handleFormatChange}
            >
              <MenuItem value={"BINEX"}>BINEX</MenuItem>
              <MenuItem value={"CMR"}>CMR</MenuItem>
              <MenuItem value={"NMEA"}>NMEA</MenuItem>
              <MenuItem value={"RAW"}>RAW</MenuItem>
              <MenuItem value={"RTCA"}>RTCA</MenuItem>
              <MenuItem value={"RTCM 2.1"}>RTCM 2.1</MenuItem>
              <MenuItem value={"RTCM 2.2"}>RTCM 2.2</MenuItem>
              <MenuItem value={"RTCM 2.3"}>RTCM 2.3</MenuItem>
              <MenuItem value={"RTCM 3.0"}>RTCM 3.0</MenuItem>
              <MenuItem value={"RTCM 3.1"}>RTCM 3.1</MenuItem>
              <MenuItem value={"RTCM 3.2"}>RTCM 3.2</MenuItem>
              <MenuItem value={"RTCM 3.3"}>RTCM 3.3</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="carrier">Signals</InputLabel>
            <Select
              labelId="carrier"
              id="carrier-select"
              value={carrier}
              label="Signals"
              onChange={handleCarrierChange}
            >
              <MenuItem value={"1"}>L1</MenuItem>
              <MenuItem value={"2"}>L2</MenuItem>
              <MenuItem value={"5"}>L5</MenuItem>
            </Select>
          </FormControl>
          <FormGroup>
            <FormControlLabel onChange={() => setGPS(!gps)} checked={gps} control={<Checkbox />} label="GPS" />
            <FormControlLabel onChange={() => setGlonass(!glonass)} checked={glonass} control={<Checkbox />} label="GLONASS" />
            <FormControlLabel onChange={() => setGalileo(!galileo)} checked={galileo} control={<Checkbox />} label="Galileo" />
            <FormControlLabel onChange={() => setBeidou(!beidou)} checked={beidou} control={<Checkbox />} label="BeiDou" />
          </FormGroup>
          <span>{navsystem}</span>
          <TextField
            fullWidth
            id="country"
            label="Country"
            onChange={handleCountryChange}
            value={country}
            error={!countryValid}
            helperText={!countryValid ? "Invalid country code." : "Enter a valid 3-digit country code."}
          />
          <TextField
            fullWidth
            id="latitude"
            label="Latitude"
            onChange={handleLatitudeChange}
            value={latitude}
            error={!latitudeValid}
            helperText={!latitudeValid ? "Invalid decimal degree coordinate." : "Enter a latitude coordinate in decmial degree format."}
          />
          <TextField
            fullWidth
            id="longitude"
            label="Longitude"
            onChange={handleLongitudeChange}
            value={longitude}
            error={!longitudeValid}
            helperText={!longitudeValid ? "Invalid decimal degree coordinate." : "Enter a longitude coordinate in decmial degree format."}
          />
          <FormGroup>
            <FormControlLabel onChange={() => setNmeaBool(!nmeaBool)} checked={nmeaBool} control={<Checkbox />} label="NMEA Required" />
          </FormGroup>
          <FormControl fullWidth>
            <InputLabel id="solution">Solution</InputLabel>
            <Select
              labelId="solution"
              id="solution-select"
              value={solution}
              label="Solution Type"
              onChange={handleSolutionChange}
            >
              <MenuItem value={"0"}>Single base</MenuItem>
              <MenuItem value={"1"}>Network</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            id="generator"
            label="Generator"
            onChange={handleGeneratorChange}
            value={generator}
            error={!generatorValid}
            helperText={!generatorValid ? "Invalid generator name." : "Enter a generator name(100 chars)."}
          />
          <TextField
            fullWidth
            id="compression"
            label="Compression"
            onChange={handleCompressionChange}
            value={compression}
            error={!compressionValid}
            helperText={!compressionValid ? "Greater than 100 characters." : "Enter compression type(100 chars)."}
          />
          <FormControl fullWidth>
            <InputLabel id="authentication">Authentication</InputLabel>
            <Select
              labelId="authentication"
              id="authentication-select"
              value={authentication}
              label="Authentication"
              onChange={handleAuthenticationChange}
            >
              <MenuItem value={"N"}>None</MenuItem>
              <MenuItem value={"B"}>Basic</MenuItem>
              <MenuItem value={"D"}>Digest</MenuItem>
            </Select>
          </FormControl>
          <FormGroup>
            <FormControlLabel onChange={() => setFeeBool(!feeBool)} checked={feeBool} control={<Checkbox />} label="Access fee" />
          </FormGroup>
          <TextField
            fullWidth
            id="bitrate"
            label="Bitrate"
            onChange={handleBitrateChange}
            value={bitrate}
            error={!bitrateValid}
            helperText={!bitrateValid ? "Not an integer integer." : "Enter an integer."}
          />
          {success && <Alert severity="success">{message}</Alert>}
          {error && <Alert severity="error">{message}</Alert>}
          {submitting ? (
            <CircularProgress size="1rem" color="inherit" />
          ) : (<Button
            variant="text"
            onClick={submit}
            disabled={!formValid()}
          >Save</Button>)}
        </Stack>
      </React.Fragment>
    );
  }
}