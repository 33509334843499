import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PeopleIcon from '@mui/icons-material/People';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import { LinearProgress } from '@mui/material';
import BytesDisplay from '../../../components/BytesDisplay';


function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export default function MountsTable(props) {

  const [age, setAge] = useState(10);
  const [current, setCurrent] = useState(false);
  const prevAge = useRef(10);

  useEffect(() => {
    prevAge.current = age;
  }, [age]);

  useEffect(() => {
    setAge(new Date());
  }, [props]);

  useEffect(() => {
    const timer = setInterval(() => {
      let currentDate = new Date();
      let diff = currentDate - prevAge.current;
      let diffSeconds = diff / (1000);
      if (diffSeconds <= 5) setCurrent(true);
      else setCurrent(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <React.Fragment>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Mount</TableCell>
          <TableCell>Status</TableCell>
          <TableCell align="left">Type</TableCell>
          <TableCell align="left">Users</TableCell>
          <TableCell align="left">Up&nbsp;Time</TableCell>
          <TableCell align="left">Data Stream</TableCell>
        </TableRow>
      </TableHead>
        {current && (
        <TableBody>
          {props.activeMounts.map((mount, index) =>
            <TableRow
              key={mount.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">
                  {mount.connected ? (
                    <Chip label={mount.name} color="success" />
                  ) : (
                    <Chip label={mount.name} color="error" />
                  )
                  }
                </TableCell>
                <TableCell component="th" scope="row">
                  {mount.connected ? (
                  <span style={{ color: "green" }}>Online</span>
                    ) : (
                      <Chip label="Offline" color="error" variant="outlined" />
                    )
                    }
                </TableCell>
                <TableCell align="left">{mount.type}</TableCell>
                <TableCell align="left">{mount.clients}</TableCell>
                <TableCell align="left">{mount.time}</TableCell>
                <TableCell align="left"><BytesDisplay bytes={mount.bytes} /></TableCell>
              </TableRow>
            )}
          </TableBody>          
      )}
    </Table>
      {!current && <LinearProgress speed={100} />}
      {props.activeMounts.length === 0 && "No mountpoints"}
    </React.Fragment >
  );
}
