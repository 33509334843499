import React from "react";
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import RelayService from './relayService';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import styled, { css } from 'styled-components';
import DeleteByIdButton from '../../components/Buttons/DeleteByIdButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MountpointSelector from './components/MountPointSelector';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const relayservice = new RelayService();

export const GridBreak = styled.div`
  width: 100%
`;

export default function RelayUpdate(props) {
  let navigate = useNavigate();
  let { id } = useParams(); // Relay ID

  const [loading, setLoading] = useState(false);

  const [relay, setRelay] = useState({});

  const [caster, setCaster] = useState("");
  const [casterValid, setCasterValid] = useState(true);

  const [port, setPort] = useState(10000);
  const [portValid, setPortValid] = useState(true);

  const [username, setUsername] = useState("");
  const [usernameValid, setUsernameValid] = useState(true);

  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(true);

  const [alias, setAlias] = useState("");
  const [aliasValid, setAliasValid] = useState(true);

  const [dip, setDip] = useState(false);

  const [mountSelection, setMountSelection] = useState([]);
  const [mountSelected, setMountSelected] = useState("");
  const [montsLoading, setMountsLoading] = useState(false);
  const [downloadError, setDownloadError] = useState("");

  const [relayValidated, setRelayValidated] = useState(false);
  const [relayChecked, setRelayChecked] = useState(false);
  const [relayValidatedError, setRelayValidatedError] = useState("");

  // Retrieve relay info
  useEffect(() => {
    fetchData();
  }, []);

  // Populate form with current relay info
  useEffect(() => {
    populateRelayForm(relay);
  }, [relay]);

  const fetchData = () => {
    setLoading(true);
    relayservice.get(id).then((response) => {
      setRelay(response);
      setLoading(false);
    });
  };

  const populateRelayForm = (relay) => {

    setCaster(relay.server);
    setPort(relay.port);
    setUsername(relay.username);
    setPassword(relay.password);
    setMountSelection([{name: relay.relay_mountpoint, identifier: ""}])
    setMountSelected(relay.relay_mountpoint);
    setAlias(relay.name);
    setDip(relay.dip);
  };


  const handleChangeMountpoint = (event) => {
    setMountSelected(event.target.value);
    resetCasterValidation();

  };

  const handleCasterChange = (e) => {
    setCaster(e.target.value);
    setCasterValid(validateCaster(e.target.value));
    resetCasterValidation();
    clearMountpointList();
  };

  const handlePortChange = (event) => {
    let newPort = cleanPort(event.target.value);
    setPort(Number(newPort));
    setPortValid(validatePort(newPort));
    resetCasterValidation();
    clearMountpointList();
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    resetCasterValidation();
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    resetCasterValidation();
  };

  const handleAliasChange = (event) => {
    let newAlias = cleanAlias(event.target.value);
    setAliasValid(validateAlias(newAlias));
    setAlias(newAlias);
  };

  function resetCasterValidation() {
    setRelayChecked(false);
    setRelayValidated(false);
    setRelayValidatedError("");
  }

  function clearMountpointList() {
    setMountSelection([]);
    setMountSelected("");
    setDownloadError("");
    setMountsLoading(false);
  }

  const GetMountpointsButton = () => {
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);

    const getMountpoints = () => {
      setDownloadError("");
      setLoading(true);
      relayservice.downloadMountpoints(caster, port).then((response) => {
        setLoading(false);
        if (response.result === true) {
          setMountSelection(response.mountpoints);
          setMountSelected(response.mountpoints[0].name);
        }
        else {
          setDownloadError(response.message);
        }
      });
    };
    return (
      <Button variant="contained" size="large" fullWidth onClick={getMountpoints} disabled={loading || !caster || !casterValid || !portValid}>
        {!checked && !loading && <DownloadIcon />}
        {loading && (<CircularProgress size="1rem" color="inherit" />)}
        {!loading && checked && <CheckIcon color="success" />}
        {!loading && checked && <ErrorOutlineIcon color="error" />}
      </Button>
    );
  }

  const SourceTableEntryButton = () => {
    return (
      <Button variant="contained" onClick={() => navigate("/caster/mountpoint/" + relay.id)}>
      Mountpoint Details
    </Button>
    );
  }

  const ValidateRelayButton = () => {
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [checked, setChecked] = useState(false);
    const [message, setMessage] = useState("");

    const validaterelay = () => {
      setLoading(true);
      resetCasterValidation();
      relayservice.validateNewRelay(
        {
          server: caster,
          port: port,
          username: username,
          password: password,
          name: mountSelected,
          dip: dip
        }).then((response) => {
          setLoading(false);
          setChecked(true);
          if (response.result === true) {
            setRelayValidated(true);
          }
          else {
            setRelayValidatedError(response.message);
          }
        }).catch(function (error) {
          setRelayValidatedError("Failed to test relay. Try again.");
        });
    };
    return (
          <Button variant="contained" onClick={validaterelay} disabled={(loading || submitted || !casterValid || !portValid || !username || !password || !mountSelected)}>
            {!loading ? "Test Connection" : (<CircularProgress size="1rem" color="inherit" />)}
            {relayValidated && (
            <CheckCircleIcon color="success" />
        )}
          </Button>
    );
  }

  const SaveRelayButton = (props) => {
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);

    const submitrelay = () => {
      setLoading(true);
      setError(false);
      setMessage("");
      setSubmitted(false);
      relayservice.patch(
        {
          id: id,
          server: caster,
          port: port,
          username: username,
          password: password,
          relay_mountpoint: mountSelected,
          name: alias,
          dip: dip,
        }).then((response) => {
          setLoading(false);
          setError(false);
          setMessage("");
          setSubmitted(true);
        })
        .catch((error) => {
          setLoading(false);
          setError(true);
          setSubmitted(false);
          setMessage(error.message);
        });
    };
    return (
      <React.Fragment>
        { !submitted && 
          <Button variant="contained" onClick={submitrelay} disabled={(loading || submitted)}>
            {!submitted && "Save"}
            {loading && (<CircularProgress size="1rem" color="inherit" />)}
            {!loading && error && <ErrorOutlineIcon color="error" />}
          </Button>
        }
          { submitted && !error && 
          <Alert severity="success">Saved</Alert>
          }
      </React.Fragment>
    );
  }

  if(!loading) {
    return (
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={12}>
          <h1>{relay.name} Relay Detail</h1>
        </Grid>
        <Grid item xs={8}>
            <TextField
            fullWidth
            id="caster-ip"
            label="Caster IP or Domain name"
            onChange={handleCasterChange}
            value={caster}
            error={!casterValid}
            helperText={!casterValid ? "Invalid caster address" : "Enter a valid dns name or IP."}
            autoFocus
          />
        </Grid>
        <Grid item xs={4}>
            <TextField
            fullWidth
            id="caster-port"
            label="Caster Port"
            onChange={handlePortChange}
            value={port}
            error={!portValid}
            helperText={!portValid ? "Port number must be 1-63535." : "Enter a port number 1-63535."}
          />
        </Grid>
        <Grid item xs={4}>
          <ToggleButtonGroup
            value={dip}
            exclusive
            onChange={() => {
              setDip(!dip);
              setRelayValidated(!dip);
            }}
            aria-label="DIP"
        >
            <ToggleButton value={false} aria-label="NTRIP">NTRIP</ToggleButton>
            <ToggleButton value={true} aria-label="DIP">DIP</ToggleButton>
        </ToggleButtonGroup>
        </Grid>
        <GridBreak />
        {!dip && <React.Fragment><Grid item xs={12}>
          Selected Mountpoint: {mountSelected}
        </Grid><Grid item xs={12}>
            <MountpointSelector ip={caster} port={port} setSelected={setMountSelected} selected={mountSelected} />
          </Grid><GridBreak /><Grid item xs={6}>
            <TextField
              fullWidth
              id="username"
              label="Username"
              onChange={handleUsernameChange}
              value={username}
              error={!usernameValid}
              helperText={!usernameValid ? "Check username." : "Enter a valid username."}
              disabled={!mountSelected} />
          </Grid><Grid item xs={6}>
            <TextField
              fullWidth
              id="password"
              label="Password"
              onChange={handlePasswordChange}
              value={password}
              error={!passwordValid}
              helperText={!passwordValid ? "Check password." : "Enter a valid password."}
              disabled={!mountSelected} />
          </Grid></React.Fragment>}
        <Grid item xs={6}>
          <TextField
              id="alias"
              label="Local Mountpoint Alias"
              onChange={handleAliasChange}
              value={alias}
              error={!aliasValid}
              helperText={!aliasValid ? "Invalid mountpoint name." : "Enter a valid mountpoint name."}
            />
        </Grid>
        <Grid container justifyContent="center" >
          <SaveRelayButton refresh={() => props.refresh()} />
        </Grid>
        {relayValidatedError && 
        <Grid item xs={12}>
          <Alert severity="error">
            {relayValidatedError}
          </Alert>
        </Grid>
        }
        <Grid item xs={12}>
          <ValidateRelayButton />
        </Grid>
        <Grid item xs={12}>
          <SourceTableEntryButton />
        </Grid>
        <Grid item xs={12}>
          <DeleteByIdButton service={relayservice} id={relay.id} nextUrl={"/caster/relays/"} title={`Delete Relay ${relay.name}`} />
        </Grid>
      </Grid>
    );
  }
}



const validateCaster = (caster) => {
  if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(caster)) {
    return true;
  }
  else if (/([a-z0-9|-]+\.)*[a-z0-9|-]+\.[a-z]+/.test(caster)) {
    return true;
  }
  return false;
}

const validatePort = (port) => {
  if (/^[0-9][0-9]?[0-9]?[0-9]?[0-9]?$/.test(port)) {
    if (port > 0 && port <= 65535) {
      return true;
    }
  }
  return false;
}

const cleanPort = (port) => {
  return port.replace(/[^0-9.]/g, '');
}


const validateAlias = (alias) => {
  if (/[&\/\\#,+()$~%.'":*?<>{}]/.test(alias)) {
    return false;
  }
  else if (alias.length > 100) {
    return false;
  }
  else {
    return true;
  }
}

const cleanAlias = (alias) => {
  return alias.replace(" ", '_');
}
