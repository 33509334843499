import axiosInstance from '../../util/axiosAPI';
import { API_URL } from '../../config';

export default class MountpointService {

  constructor() { }

  list() {
    const url = `${API_URL}/api/ntrip/mountpoints/`;
    return axiosInstance.get(url).then(response => response.data);
  }

  get(id) {
    const url = `${API_URL}/api/ntrip/mountpoints/${id}/`;
    return axiosInstance.get(url).then(response => response.data);
  }

  // update(mountpoint) {
  //   const url = `${API_URL}/api/ntrip/mountpoints/${mountpoint.id}/`;
  //   return axiosInstance.put(url, mountpoint);
  // }

  update(mountpoint) {
    const url = `${API_URL}/api/ntrip/mountpoints/${mountpoint.id}/`;
    return axiosInstance.patch(url, mountpoint);
  }
}